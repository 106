
import * as React from "react"
import PropTypes from "prop-types"

const arrowStatic = ({ color }) => {

  return (
        <svg style={{display: 'inline-block'}} width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0" y1="6.5" x2="13" y2="6.5" stroke={color}/>
            <path d="M13 1L19 6.5L13 12" stroke={color} strokeMiterlimit="10" strokeLinejoin="round"/>
        </svg>
  )
}

arrowStatic.propTypes = {
  color: PropTypes.string,
}

arrowStatic.defaultProps = {
  color: '#ffffff',
}

export default arrowStatic
