import React from "react"
const svg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={23}
    fill="none"
    viewBox="0 0 22 23"
    {...props}
  >
    <path
      fill="#9F927D"
      fillRule="evenodd"
      d="M7.035.405c.329 0 .595.266.595.595v2.028h6.998V1a.595.595 0 1 1 1.19 0v2.028h4.798c.338.002.663.135.904.37.24.236.38.555.386.89v16.835a1.277 1.277 0 0 1-.38.9 1.307 1.307 0 0 1-.91.376H1.29c-.343 0-.671-.134-.913-.374A1.269 1.269 0 0 1 0 21.123V4.288a1.27 1.27 0 0 1 .384-.892c.242-.236.567-.368.907-.368h5.15V1c0-.329.266-.595.594-.595Zm7.593 4.43v1.481a.595.595 0 1 0 1.19 0V4.828c.208.08.399.203.558.362a1.591 1.591 0 0 1 .345 1.743c-.124.292-.332.54-.599.715-.267.175-.58.267-.9.265a1.623 1.623 0 0 1-1.139-.468 1.587 1.587 0 0 1-.195-2.017 1.612 1.612 0 0 1 .74-.594ZM6.44 6.315V4.837l-.003.002c-.296.12-.55.325-.728.588a1.585 1.585 0 0 0-.273.889h-.009a1.573 1.573 0 0 0 .468 1.129 1.608 1.608 0 0 0 1.14.468c.32.004.633-.087.901-.26.268-.174.478-.422.603-.714a1.58 1.58 0 0 0-.34-1.746 1.617 1.617 0 0 0-.569-.368v1.491a.595.595 0 1 1-1.19 0ZM5.128 19.475h-1.88v-1.851h1.88v1.85Zm0-3.77h-1.88v-1.859h1.88v1.86Zm0-3.777h-1.88v-1.851h1.88v1.85Zm4.36 7.547H7.614v-1.851h1.872v1.85Zm0-3.77H7.614v-1.859h1.872v1.86Zm0-3.777H7.614v-1.851h1.872v1.85Zm4.367 7.547h-1.89v-1.851h1.873l.017 1.85Zm0-3.77h-1.89v-1.859h1.873l.017 1.86Zm0-3.777h-1.89v-1.851h1.873l.017 1.85Zm4.367 7.547h-1.88v-1.851h1.88v1.85Zm0-3.77h-1.88v-1.859h1.88v1.86Zm0-3.777h-1.88v-1.851h1.88v1.85Z"
      clipRule="evenodd"
    />
  </svg>
)
export default svg
