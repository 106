import React from 'react'
import tw, {css} from 'twin.macro';
import SlideToggle from "react-slide-toggle"
import Plus from '../vector/plus'
import ClientOnly from '../../helpers/ClientOnly'
import SSROnly from '../../helpers/SSROnly'

const TextoEscondido = ({content}) => {
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: content.data.title }}
                css={[tw`text-gris1 text-obsmall font-bold leading-6 uppercase`, css1]}
            />
            <SSROnly>
                <div dangerouslySetInnerHTML={{ __html: content.data.texto_con_formato1 }}
                css={[tw`py-4 text-gris1 text-obsmall font-semibold leading-6`, css1]}
                />
            </SSROnly>
            <ClientOnly>
                <SlideToggle collapsed={true}>
                    {({ toggle, setCollapsibleElement }) => (
                        <div css={tw`pb-4`}>
                            <div onClick={toggle} css={tw`inline-block p-2 -ml-2 cursor-pointer`}>
                                <Plus color="#474747" />
                            </div>
                            <div ref={setCollapsibleElement}>
                                <div dangerouslySetInnerHTML={{ __html: content.data.texto_con_formato1 }}
                                     css={[tw`pb-4 text-gris1 text-obsmall2 font-semibold leading-6`, css1]}
                                />
                            </div>
                        </div>
                    )}
                </SlideToggle>
            </ClientOnly>
            
        </div>
    )
}

const css1 = css`
    strong{
        ${tw`font-bold`}
    }
    a{
        ${tw`text-marron1`}
        ${tw`underline`}
    }
`


export default TextoEscondido