import React, { useRef, useEffect } from "react"
import lottie from "lottie-web"
import tw, { css } from "twin.macro"

const Animacion = props => {
  const { animationData, animationSpeed, twStyles } = props

  const animationRef = useRef()
  let anim = null
  useEffect(() => {
    anim = lottie.loadAnimation({
      container: animationRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: JSON.parse(animationData),
    })
    if (animationSpeed) {
      anim.setSpeed(parseFloat(animationSpeed))
    }

    return () => anim.destroy() // optional clean up for unmounting
  }, [])

  //   function handleStop() {
  //     anim.pause();
  //   }
  //   function handleStart() {
  //       onsole.log(anim)
  //     anim.play();
  //   }

  return (
    <div css={twStyles ? twStyles : tw`w-full h-full`} ref={animationRef} />
  )
}

export default Animacion
