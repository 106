import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import TituloEnlace from "./tituloEnlace"
import ProjectCard from "../mini/projectCard"
import tw, { css } from "twin.macro"
import { GeneralContext } from "../../contexts/generalContext"

const ProjectList = ({ content, lang }) => {
  const generalContext = useContext(GeneralContext)
  const { t } = generalContext

  if(!content?.data?.blocks) return null

  const projects = content.data.blocks.map((p, idx) => (
    {
      title: p.title,
      modelos: JSON.parse(p.texto_corto1),
      por: p.texto_con_formato1,
      procedencia: p.texto_con_formato2,
      ruta: p.url,
      image: p.image,
      image_mobile: p.image_mobile,
      // descripcion: p.texto_sin_formato1, //Descripción para listados. Desactivado por el momento.
    }
  ))

  
  //onsole.log("projects",projects)
  return (
    <>

      <div tw="md:grid md:grid-cols-3 md:gap-x-6">
        {projects.map(project => {
          return (
            <div key={project.ruta}>
              <ProjectCard
                project={project}
                t={t}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ProjectList