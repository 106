import React from 'react'
import {styled} from 'twin.macro';

const Margen = ({content}) => {

    return (
        <StyledMargin heightMobile={content.data.numero1} heightTablet={content.data.numero2} />
    )
}

const StyledMargin = styled.div`
    height: ${props => props.heightMobile}rem;
    @media (min-width: 768px) {
        height: ${props => props.heightTablet}rem;
    }
`
export default Margen