import React from 'react'
import tw, {styled} from 'twin.macro';

import TituloEnlace from './tituloEnlace'
import htmlFilters from "../../helpers/htmlFilters";

const YoutubeVideoConTexto = ({content}) => {

    const ParteVideo = ({ estaALaDerecha }) => {
        return (<div css={[tw`md:flex-1`, estaALaDerecha ? tw`md:ml-4` : tw`md:mr-4`]}>
            <div css={tw`relative w-full h-0 overflow-hidden `} style={{paddingBottom: '56.25%'}}>
                <iframe
                    css={tw`absolute w-full h-full top-0 left-0`}
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${content.data.texto_corto1}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            </div>
        </div>)
    }

    const ParteTexto = ({ estaALaIzquierda }) => {
        return (<div css={[tw`md:flex-1 md:block px-5 md:px-0 py-2 md:py-0`, content.data.checkbox2 ? tw`hidden` : tw`block`, estaALaIzquierda ? tw`md:mr-4` : tw`md:ml-4`]}>
            <StyledParrafo>{htmlFilters(content.data.texto_con_formato1)}</StyledParrafo>
        </div>)
    }
    const tieneTitulo = content.data.url && content.data.title

    return (
        <>
        { tieneTitulo && <TituloEnlace
                    content={{
                        data: {
                            url: content.data.url,
                            title: content.data.title,
                            checkbox1: true,
                        }
                    }}
                />
        }
        <div css={[tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden bg-black`, !tieneTitulo && tw`md:pt-6`]}>
            <div css={tw`md:px-9 md:pb-6`}>
                <div css={tw`max-w-screen-xl my-0 mx-auto px-0 pb-4 md:flex`}>
                    {
                        content.data.checkbox1 ? //Video a la derecha
                        <>
                            <ParteTexto estaALaIzquierda />
                            <ParteVideo estaALaDerecha />
                        </>
                        :
                        <>
                            <ParteVideo />
                            <ParteTexto />
                        </>
                    }
                </div>
            </div>
        </div>
        </>
    )
}

const StyledParrafo = styled.div`
    ${tw`pt-3 md:pt-0`}
    p{
        ${tw`text-white text-obsmall2 font-light leading-5`}
        margin-top: 1rem;
        &:first-of-type{
            margin-top: 0;
        }
    }
    a{
        font-weight: 400;
        text-decoration: underline;
    }
`


export default YoutubeVideoConTexto