import React, { useContext } from "react";
import * as s from "./ctaButtons.module.css";
import { GeneralContext } from "../../contexts/generalContext"

const CTAButtons = () => {
  const generalContext = useContext(GeneralContext)
  const { ctaModalShow } = generalContext
  return (
    <div className={s.container}>
      <div
        onClick={() => ctaModalShow('contacto')}

      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={46}
          height={41}
          fill="none"
        >
          <path
            fill="#9F937D"
            fillRule="evenodd"
            d="M25.854 18.46h-5.499a6.98 6.98 0 0 0-6.98 6.805l-11.11-8.622L23.167.383l20.864 16.285-11.197 8.71a6.98 6.98 0 0 0-6.98-6.918Zm.493-11.453a5.723 5.723 0 0 0-3.18-.964 5.71 5.71 0 0 0-5.723 5.723 5.723 5.723 0 1 0 8.903-4.758Z"
            clipRule="evenodd"
          />
          <path
            fill="#9F937D"
            d="M45.562 38.74a1.53 1.53 0 0 1-.075.485L31.975 28.724l13.002-10.115.585-.485v20.615ZM2.489 40.618l20.677-16.074 20.678 16.074H2.49ZM14.358 28.724.772 18.161V38.74c-.002.165.024.329.074.486l13.512-10.501Z"
          />
        </svg>
        <span>Contacta con nosotros</span>
      </div>
      <div
        onClick={() => ctaModalShow('cita')}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={46}
          height={43}
          fill="none"
        >
          <path
            fill="#9F937D"
            fillRule="evenodd"
            d="M35.957 4.704h7.713a1.891 1.891 0 0 1 1.892 1.866v33.58a1.891 1.891 0 0 1-1.904 1.879h-.747l-4.976-4.977h2.488v-2.688h-2.762v2.489l-1.655-1.642a8.422 8.422 0 0 0 1.717-3.733h2.725v-2.737h-2.6a8.709 8.709 0 0 0-17.17 0H18.39v2.737h2.352a8.634 8.634 0 0 0 13.126 5.574h.124v-.087c.219-.13.43-.27.634-.423l5.537 5.486H2.663a1.891 1.891 0 0 1-1.891-1.866V6.57a1.891 1.891 0 0 1 1.891-1.866h7.714V1.718a.747.747 0 0 1 1.493 0v2.986h10.55V1.718a.747.747 0 0 1 1.493 0v2.986h10.55V1.718a.746.746 0 1 1 1.494 0v2.986ZM5.55 37.052h2.762v-2.737H5.55v2.737Zm0-5.574h2.762v-2.737H5.55v2.737Zm0-5.561h2.762V23.18H5.55v2.737Zm0-5.574h2.762v-2.737H5.55v2.737ZM9.202 8.184a2.339 2.339 0 0 0-.455 1.372 2.376 2.376 0 1 0 4.753 0 2.34 2.34 0 0 0-1.63-2.214v2.214a.746.746 0 1 1-1.493 0V7.342a2.34 2.34 0 0 0-1.175.842Zm2.767 28.868h2.762v-2.737H11.97v2.737Zm0-5.574h2.762v-2.737H11.97v2.737Zm0-5.561h2.762V23.18H11.97v2.737Zm0-5.574h2.762v-2.737H11.97v2.737Zm9.182 16.709v-2.737H18.39v2.737h2.762ZM18.39 25.917h2.762V23.18H18.39v2.737Zm0-5.574h2.762v-2.737H18.39v2.737Zm2.857-12.159a2.34 2.34 0 0 0-.456 1.372 2.376 2.376 0 0 0 4.753 0 2.34 2.34 0 0 0-1.63-2.214v2.214a.746.746 0 1 1-1.493 0V7.342a2.34 2.34 0 0 0-1.175.842Zm3.563 12.159h2.762v-2.737h-2.762v2.737Zm6.432 0h2.75v-2.737h-2.75v2.737Zm3.982-8.423a2.377 2.377 0 0 1-2.364-2.364 2.339 2.339 0 0 1 1.63-2.214v2.214a.746.746 0 0 0 1.492 0V7.342a2.339 2.339 0 0 1 1.605 2.214 2.376 2.376 0 0 1-2.363 2.364Zm2.438 13.997h2.787l-.025-2.737h-2.762v2.737Zm0-5.574h2.787l-.025-2.737h-2.762v2.737Z"
            clipRule="evenodd"
          />
          <path
            fill="#9F937D"
            fillRule="evenodd"
            d="m28.18 36.442-.61-.124.088-.1a6.694 6.694 0 0 1-2.762-1.443 7.523 7.523 0 0 1-.921-.97l-.01-.014a5.544 5.544 0 0 1-.35-.496 8.354 8.354 0 0 1-.399-.735l-.15-.373a6.456 6.456 0 0 1-.31-.983v-.186a6.22 6.22 0 0 1 0-2.489v-.186c.079-.335.183-.664.31-.983l.15-.373c.12-.247.253-.488.398-.722.087-.144.18-.273.276-.405l.085-.117c.282-.355.594-.683.933-.983a6.842 6.842 0 0 1 2.762-1.444l.61-.124a5.966 5.966 0 0 1 1.057-.1c.368 0 .735.038 1.095.113 1.42.229 2.73.91 3.733 1.94.268.27.513.561.734.871.062.038.062.125.062.125.227.323.418.669.572 1.032a.174.174 0 0 1 0 .075 6.703 6.703 0 0 1 .386 3.931 6.767 6.767 0 0 1-1.02 2.402 6.744 6.744 0 0 1-4.566 2.862 6.408 6.408 0 0 1-1.095.111 5.99 5.99 0 0 1-1.058-.112Zm-2.525-9.916v6.88h6.967v-6.88h-6.967Z"
            clipRule="evenodd"
          />
        </svg>
        <span>Pídenos una cita</span>
      </div>
    </div>
  );
};

export default CTAButtons;
