import React, {useContext} from 'react'
import CLink from '../cLink'
import Image from '../image'
import Arrow from '../vector/arrow'
import tw, {styled} from 'twin.macro';
import { useSpring, animated as a } from '@react-spring/web'
import useScroll from "../../helpers/useScroll"
import { useInView } from 'react-intersection-observer'

const ListadoCocinasSimilares = ({content}) => {
    const windowData = useScroll()
    const { windowSize } = windowData
    return (
        <ul css={tw`md:grid md:grid-cols-3 md:gap-1`}>
            {content.data.blocks.map((block, i) => {
                if(!block.image.base64){
                    return (<div>FALTA IMAGEN</div>)
                }
                return (
                <Cocina key={i} block={block} number={i} windowSize={windowSize} />
            )})
            }
        </ul>
    )
}

const Cocina = ({block, windowSize, number }) => {
    const [ hover, setHover ] = React.useState(false)
    const { ref, inView } = useInView({
        /* Optional options */
        // threshold: 0,
        triggerOnce: true,
    })
    const transitionSpring = useSpring({
        transform: !inView ? "translate(0px,30px)" : "translate(0px,0px)",
        opacity: !inView ? 0 : 1,
        delay: number * 150,
    })

    const backgroundSpring = useSpring({
        transform: windowSize.width < 768 ? "scale(1) translate(0%,0%) rotate(0deg)" : (hover ?
        "scale(1.5) translate(-18%,40%) rotate(-45deg)"
        :
        `scale(1.5) translate(0%,85%) rotate(${number % 2 ? "0" : "-90"}deg)`),
        delay: hover ? 0 : 0
        }
    )
    const textSpring = useSpring({
        opacity: windowSize.width < 768 || hover  ?
        1
        :
        0,
        delay: hover ? 150 : 0
    })
    // const backgroundLeftSpring = useSpring({
    //     transform: windowSize.width > 767 && hover ?
    //     "translate(-40%,0%) skewX(-45deg)"
    //     :
    //     "translate(-100%,0%) skewX(0deg)",
    //     delay: hover ? 0 : 150
    //     }
    // )
    // const textLeftSpring = useSpring({
    //     transform: windowSize.width > 767 && hover ?
    //     "translate(20%,0%)"
    //     :
    //     "translate(0%,0%)",
    //     delay: hover ? 150 : 0
    //     }
    // )
    
    // const textSpring = useSpring({
    //     opacity: windowSize.width > 767 && hover ? 1 : 0,
    //     }
    // )
    return (
        <li ref={ref} css={tw`block relative overflow-hidden`}>
            <a.div style={transitionSpring}>
                <CLink
                    to={block.url}
                    css={tw`block relative mb-9 md:mb-1`}
                    onMouseEnter={() => {setHover(true)}}
                    onMouseLeave={() => {setHover(false)}}
                >
                        
                    <div css={tw``}>
                        <Image
                            objectFit={"cover"}
                            aspectRatio={windowSize.width < 768 ? 1.56 : 1}
                            alt={block.image.description}
                            base64={block.image.base64}
                            src={block.image.url880}
                            srcSet={`${block.image.url220} 220w, ${block.image.url440} 440w, ${block.image.url880} 880w, ${block.image.url980} 980w, ${block.image.url1760} 1200w, ${block.image.url2000} 2000w`}
                        />
                    </div>
                    <a.div 
                        css={tw`absolute bg-black md:bg-white w-full inset-0 opacity-70 md:opacity-100`}
                        style={backgroundSpring}
                    />
                    <a.div
                        css={tw`absolute md:inset-x-0 top-0 md:top-auto md:bottom-0 px-5 md:px-7 py-4 text-white md:text-marron1`}
                        style={textSpring}
                    >
                        
                            <div
                                dangerouslySetInnerHTML={{__html: block.title}}
                                css={tw`pb-1 text-sm md:text-obmed uppercase md:font-semibold `}
                            />
                            <StyledParrafo
                                dangerouslySetInnerHTML={{__html: block.texto_con_formato1}} />
                            <div
                                css={tw`pt-4 pb-2`}
                            >
                                <Arrow color={windowSize.width < 768 ? "white" : "#847360"} />
                            </div>
                    </a.div>
                </CLink>
            </a.div>
        </li>
    )
}

const StyledParrafo = styled.div`
    p{
        ${tw`text-obsmall2`};
        ${tw`leading-5`}
    }
    a{
        font-weight: 400;
        text-decoration: underline;
    }
`
export default ListadoCocinasSimilares