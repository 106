import React from "react"
// import TituloEnlace from "./tituloEnlace"
import tw, { css } from "twin.macro"
import { useSpring, animated as a } from "@react-spring/web"
import Arrow from '../vector/arrow'
import CLink from '../cLink'
import Image from "../imagev2"

const EnlacesDirectos = ({ content }) => {
  return (
    <>
      <ul css={[tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 `, twGap3px]}>

        {
          content.data.blocks.map((block, i) => (
            <EnlaceDirecto
              key={block.url + i}
              block={block}
            />
          ))
        }
      </ul>
    </>
  )
}


const EnlaceDirecto = ({
  block,
  // activo,
}) => {

  return (

    <li
      css={tw`relative`}
    >


      <CLink
        to={block.url}
        css={tw`block h-full h-52`}
      // onMouseEnter={() => {setHover(true)}}
      // onMouseLeave={() => {setHover(false)}}
      >
        <Image
          objectFit={"cover"}
          imageTablet={block.image}
          imageMobile={block.image_mobile}
          widths={["440px", "440px", "440px"]}
          base64={[block.image_mobile.base64, block.image.base64]}
          styles={{ objectFit: 'cover', position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        />
        <div
          style={{ width: '100%', height: '100%', position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(71,71,71,0.3)' }}
        />
        <div css={tw`p-4 relative z-10`}>
          <span css={tw`block pb-1 text-white text-obmed md:text-obmed3 font-bold uppercase `}>{block.title}</span>
          <Arrow />
        </div>
      </CLink>

    </li>
  )
}


const twGap3px = css`
  gap: 3px;
`


export default EnlacesDirectos
