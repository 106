import React, { useRef } from "react"
import CLink from "../cLink"
import Image from "../imagev2"
import Arrow from "../vector/arrow"
import tw, { styled } from "twin.macro"
import { useSpring, animated as a } from "@react-spring/web"
import useScroll from "../../helpers/useScroll"
import { useInView } from "react-intersection-observer"

const ListadoCocinas = ({ content }) => {
  const { windowSize } = useScroll()
  return (
    <ul>
      {content.data.blocks.map((block, i) => (
        <Cocina
          key={i}
          block={block}
          number={i}
          windowSize={windowSize}
          isHomeStyle={content.data.checkbox1}
        />
      ))}
    </ul>
  )
}

const Cocina = ({ block, windowSize, number, isHomeStyle }) => {
  const [hover, setHover] = React.useState(false)

  const mainDomElementRef = useRef()
  /*const { ref, inView } = useInView({
    // threshold: 0,
    triggerOnce: true,
  })
*/
  const { isElementVisible } = useScroll({
    elementVisibleConf: { domElementRef: mainDomElementRef, isTurnOffActive: true}
  })
  const inView = true; //@todo arreglar aparicion isElementVisible;
  const transitionSpring = useSpring({
    transform: !inView
      ? number % 2
        ? "translate(6%,0%)"
        : "translate(-6%,0%)"
      : "translate(0%,0%)",
    opacity: !inView ? 0 : 1,
  })

  const backgroundRightSpring = useSpring({
    transform:
      windowSize.width > 1023 && hover
        ? "translate(40%,0%) skewX(45deg)"
        : windowSize.width > 767 && hover
        ? "translate(20%,0%) skewX(45deg)"
        : "translate(100%,0%) skewX(0deg)",
    delay: hover ? 0 : 150,
  })
  const textRightSpring = useSpring({
    transform:
      windowSize.width > 767 && hover
        ? "translate(-20%,0%)"
        : "translate(0%,0%)",
    delay: hover ? 150 : 0,
  })
  const backgroundLeftSpring = useSpring({
    transform:
      windowSize.width > 1023 && hover
        ? "translate(-40%,0%) skewX(-45deg)"
        : windowSize.width > 767 && hover
        ? "translate(-20%,0%) skewX(-45deg)"
        : "translate(-100%,0%) skewX(0deg)",
    delay: hover ? 0 : 150,
  })
  const textLeftSpring = useSpring({
    transform:
      windowSize.width > 767 && hover
        ? "translate(20%,0%)"
        : "translate(0%,0%)",
    delay: hover ? 150 : 0,
  })

  // const textSpring = useSpring({
  //     opacity: windowSize.width > 767 && hover ? 1 : 0,
  //     }
  // )
  const lado = !(number % 2)
  return (
    <a.li
      ref={mainDomElementRef}
      css={[
        tw`block relative`,
        isHomeStyle && (lado ? tw`md:mr-1/7 md:mb-16` : tw`md:ml-1/7 md:mb-16`),
      ]}
      style={transitionSpring}
    >
      <CLink
        to={block.url}
        css={tw`block relative mb-9 md:mb-1 md:flex md:overflow-hidden text-marron1`}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
      >
        <div
          css={[
            tw`h-64 md:w-1/2 lg:w-2/3 md:h-80`,
            lado
              ? tw`-mr-5 md:mr-0 order-2 md:pl-1`
              : tw`-ml-5 md:ml-0 order-1 md:pr-1`,
          ]}
        >
          <Image
            objectFit={"cover"}
            imageTablet={block.image}
            imageMobile={block.image_mobile}
            widths={["748px", "474px", "850px"]}
            styles={{zIndex: -1}}
            // aspectRatio={[block.image_mobile.aspectratio, block.image.aspectratio]}
            // alt={block.image.description}
            // base64={[block.image_mobile.base64,block.image.base64]}
            // src={[block.image_mobile.url880,block.image.url880]}
            // srcSet={[`${block.image_mobile.url220} 220w, ${block.image_mobile.url440} 440w, ${block.image_mobile.url880} 880w, ${block.image_mobile.url980} 980w`,
            // `${block.image.url220} 220w, ${block.image.url440} 440w, ${block.image.url880} 880w, ${block.image.url980} 980w, ${block.image.url1760} 1200w`]}
            // sizes="(max-width: 768px) 90vw, 50vw"
          />
        </div>
        <a.div
          css={tw`hidden md:block absolute bg-white w-full inset-0`}
          style={lado ? backgroundLeftSpring : backgroundRightSpring}
        />
        <div
          css={[
            tw`relative md:w-1/2 lg:w-1/3 md:h-80 -mt-16 md:mt-0 bg-white p-4 md:p-7 `,
            lado ? tw`order-1` : tw`order-2`,
          ]}
        >
          <a.div style={lado ? textLeftSpring : textRightSpring}>
            <div
              dangerouslySetInnerHTML={{ __html: block.title }}
              css={tw`pb-1 text-obmed2 md:text-obhyperbig uppercase font-semibold md:font-light`}
            />
            <h2 css={tw`pb-6 text-obmed2 md:text-lg uppercase font-light`}>
              {block.texto_corto1}
            </h2>
            <StyledParrafo
              dangerouslySetInnerHTML={{ __html: block.texto_con_formato1 }}
            />
            <div css={tw`pt-4 pb-2`}>
              <Arrow color="#847360" />
            </div>
          </a.div>
        </div>
      </CLink>
    </a.li>
  )
}

const StyledParrafo = styled.div`
  p {
    ${tw`text-obsmall2`};
    ${tw`leading-5`}
  }
  a {
    font-weight: 400;
    text-decoration: underline;
  }
`

export default ListadoCocinas
