import React from "react"
import tw, { css } from "twin.macro"
import CLink from "../../components/cLink"
import Image from "../../components/imagev2"
// import Ellipsis from "@quid/react-ellipsis"
import Arrow from "../../components/vector/arrow"

const ProjectCard = ({ project, t, }) => {
  return (
    <div css={tw`pb-6`}>
      <div css={tw`relative`}>
        <CLink to={project.ruta}>
          <Image
            objectFit={"cover"}
            aspectRatioTablet={1.3}
            aspectRatioMobile={1.3}
            imageTablet={project.image}
            imageMobile={project.image_mobile}
            widths={["880px", "440px", "440px"]}
          />
        </CLink>
      </div>
      <CLink
        css={tw`block pt-3 pb-4  text-gris1 dark:text-gris3  hover:text-marron1 `}
        to={project.ruta}
      >
        <div
          css={[tw`h-11 text-obmed3  uppercase font-bold overflow-hidden`, ellipsis, ellipsis2]}
        >
          {project.title}
        </div>
      </CLink>
      {project.modelos && (
        <div css={tw`pb-1 border-b border-gris4 text-obsssmall2 `}>
          <span css={tw` text-gris3 uppercase mr-1 font-semibold`}>{t?.projects?.model}:</span>
          {project.modelos.map((modelo, index) => {
            return (
              <span css={tw`inline-block`} key={index}>
                {index !== 0 && (
                  <span css={tw` text-marron1 mx-1 font-semibold`}>/</span>
                )}
                <span css={tw` text-gris1 uppercase font-semibold`}>
                  {modelo.title}
                </span>
              </span>
            )
          })}
        </div>
      )}
    {(project.por || project.procedencia) && (
        <div css={tw`pt-2 pb-1 border-b border-gris4 text-obsssmall2`}>
          <span css={tw` text-gris3 uppercase mr-1 font-semibold`}>{t?.projects?.by}:</span>
          { project.por && (
                <span css={tw` text-gris1 uppercase font-semibold`}>
                  {project.por}
                </span>
          )}
          { project.procedencia && (
                <span css={tw` text-gris1 `}>
                  {' '}({project.procedencia})
                </span>
          )}
        </div>
      )}
      
      {/* <div
        css={[tw`h-12 text-obsmall text-gris1 dark:text-gris3  overflow-hidden`, ellipsis, ellipsis3]}
      >
        {project.descripcion}
      </div> */}
      <CLink
        css={tw`block py-4 text-obmed2 text-marron1 uppercase font-semibold `}
        to={project.ruta}
      >
        {t?.blog?.readMore} <Arrow color={"#847360"} />
      </CLink>
    </div>
  )
}

const ellipsis = css`
  display: -webkit-box;

  -webkit-box-orient: vertical;
  overflow: hidden;
`
const ellipsis2 = css`
  -webkit-line-clamp: 2;
`
const ellipsis3 = css`
  -webkit-line-clamp: 3;
`
export default ProjectCard
