import React, { Suspense } from 'react'
import tw, {styled} from 'twin.macro'
import ClientOnly from "../../helpers/ClientOnly"

const VideoPlayer = React.lazy(() => import('../mini/videoPlayer'));

const Video = ({content}) => {
    
    return (
        <div css={[tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden`, content.data.checkbox1 && tw`bg-black`]}>
            <div css={tw`md:px-9 `}>
                <div css={tw`max-w-screen-xl my-0 mx-auto px-0 pb-4`}>
                    <div css={tw`relative w-full h-0 overflow-hidden`} style={{paddingBottom: '56.25%'}}>
                        <ClientOnly>
                            <Suspense fallback={(<div css={tw`relative w-full h-0`} style={{paddingBottom: '56.25%'}} />)}>
                                <VideoPlayer content={content} />
                            </Suspense>
                        </ClientOnly>
                    </div>
                </div>
            </div>
        </div>
        
    )
}


export default Video