import React from 'react'
import tw, { css } from 'twin.macro';

const TituloSeccion = ({content}) => {
    const tipo = content.data.title_tipo
    const CustomTag = content.data.htmltag ? content.data.htmltag : 'div'
    return (
        <div css={tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden`}>
            <div css={tw`px-5 md:px-9 `}>
                <div css={tw`max-w-screen-xl my-0 mx-auto px-0`}>
                    <CustomTag
                        dangerouslySetInnerHTML={{__html: content.data.title}}
                        css={tipo === 'TIPO_1' ? [tw1, css1] : (tipo === 'TIPO_2' ? tw2 : tw3)}
                    />
                </div>
            </div>
        </div>
    )
}

const tw1 = tw`md:w-6/7 py-2 md:pt-4 md:pb-2 text-gris1 dark:text-gris3 font-light text-obmed2 md:text-obhyperbig uppercase`
const css1 = css`
    strong,b{
        ${tw`font-semibold`}
    }
`
const tw2 = tw`py-2 md:py-4 text-gris1 dark:text-gris3 font-semibold text-base md:text-lg uppercase`
const tw3 = tw`py-2 md:py-4 text-gris1 dark:text-gris3 text-obmed2 md:text-obsmall font-semibold md:font-bold uppercase`

export default TituloSeccion