import React from 'react'
import tw from 'twin.macro';

const Linea = ({content}) => {

    return (
        <div css={tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden`}>
            <div css={tw`px-5 md:px-9 `}>
                <div css={tw`max-w-screen-xl my-0 mx-auto px-0`}>
                    <hr css={[tw`hidden border-t border-marron1 md:block`, content.data.checkbox1 && tw`block`]} />
                </div>
            </div>
        </div>
        
    )
}


export default Linea