import React from "react"
// import TituloEnlace from "./tituloEnlace"
import tw, { css } from "twin.macro"
import { useSpring, animated as a } from "@react-spring/web"
import Arrow from '../vector/arrow'
import CLink from '../cLink'

const EnlacesBotones = ({ content }) => {
  return (
    <>
      <ul css={tw`flex flex-wrap gap-6 py-6`}>

        {
          content.data.blocks.map((block, i) => (
            <EnlaceBoton
              key={block.url + i}
              block={block}
            />
          ))
        }
      </ul>
    </>
  )
}


const EnlaceBoton = ({
  block,
  // activo,
}) => {

  return (

    <li
      css={tw`relative flex-grow flex-shrink-0 flex-[200px]`}
    >


      <CLink
        to={block.url}
        css={tw`block p-4 text-center text-white text-obmed2 md:text-obmed4 font-bold uppercase bg-marron1 hover:bg-marron2`}
      // onMouseEnter={() => {setHover(true)}}
      // onMouseLeave={() => {setHover(false)}}
      >{block.title}
      </CLink>

    </li>
  )
}





export default EnlacesBotones
