import React from 'react'
import FormularioDistribuidor from '../mini/formularioDistribuidor'

const FormularioContactoPw = ({content}) => {
    return (
        <FormularioDistribuidor nombreFormulario={content.data.texto_corto1} />
        
    )
}


export default FormularioContactoPw