import React from 'react'
import tw from 'twin.macro';

const YoutubeVideo = ({content}) => {
    //checkbox1 => fondo negro 
    return (
        <div css={[tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden`, content.data.checkbox1 && tw`bg-black`]}>
            <div css={tw`md:px-9 `}>
                <div css={tw`max-w-screen-xl my-0 mx-auto px-0 pb-4`}>
                    <div css={tw`relative w-full h-0 overflow-hidden`} style={{paddingBottom: '56.25%'}}>
                        <iframe
                            css={tw`absolute w-full h-full top-0 left-0`}
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${content.data.texto_corto1}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>
        
    )
}


export default YoutubeVideo