import React, { useContext, useCallback, useMemo } from "react"
// import { GeneralContext } from "../../contexts/generalContext"
import Image from "../imagev2"
import CLink from "../cLink"
import {
  Carousel,
  CarouselBackground,
  NavRight,
  NavLeft,
  NavGoTo,
  CarouselContextProvider,
} from "../tcq-components/Carousel"
import * as s from "./heroSlider.module.css"
import { GeneralContext } from "../../contexts/generalContext"
import tw from "twin.macro"

export const HeroSliderBackground = ({ content }) => (
  <HeroSlider content={content} isBackground />
)

const BulletsNav = ({ slides, active }) => {
  return (
    <div className={s.bullets}>
      {slides?.map((_, idx) => {
        return (
          <NavGoTo
            key={idx}
            className={idx === active ? s.isActive : ""}
            activeClassName=""
            slideNumber={idx}
          />
        )
      })}
    </div>
  )
}
const HeroSlider = ({ content, isBackground = false }) => {
  const generalContext = useContext(GeneralContext)
  const { ctaModalShow } = generalContext
  const { data } = content

  const moveScrollToTop = useCallback(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }, [])

  const slides = []

  const blocks = useMemo(() =>
    data?.blocks.map(
      (block, idx) => ({
        ...block,
        CustomTag: block.texto_corto1 ? block.texto_corto1 : "span",
      }),
      [data]
    )
  )
  blocks?.map((block, idx) => {
    slides.push({
      MainSlide: (
        <div css={tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw`}>
          <div css={tw`px-5 md:px-9`}>
            <div css={tw`max-w-screen-xl mx-auto px-0`}>
              <div className={s.slideText}>
                {block.url === "[showContactForm]" ? (
                  <block.CustomTag
                    className={s.titleLink}
                    onClick={() => ctaModalShow("contacto")}
                  >
                    {block.texto_sin_formato1}
                  </block.CustomTag>
                ) : (
                  <block.CustomTag>
                    <CLink className={s.titleLink} to={block.url}>
                      {block.texto_sin_formato1}
                    </CLink>
                  </block.CustomTag>
                )}
                <BulletsNav slides={data?.blocks} active={idx} />
              </div>
            </div>
          </div>
        </div>
      ),
      BackgroundSlide: (
        <Image
          objectFit={"cover"}
          imageTablet={block.image}
          imageMobile={block.image_mobile}
          widths={["880px", "980px", "1200px"]}
          className={s.backgroundImage}
        />
      ),
    })
  })
  if (isBackground) {
    return (
      <CarouselBackground
        slides={slides}
        containerClassName={s.backgroundContainer}
      />
    )
  }

  return (
    <div css={tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw`}>
      <div className={s.container}>
        <CarouselContextProvider onClickCallBack={moveScrollToTop}>
          <Carousel
            slides={slides}
            containerClassName={s.slideTextContainer}
            autoplayTime={3000}
          />
          <div className={s.navButtonsContainer}>
            <div css={tw`px-5 md:px-9`}>
              <div css={tw`max-w-screen-xl mx-auto px-0`}>
                <div className={s.navButtons}>
                  <NavLeft className={s.buttonLeft}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={30}
                      height={18}
                      fill="none"
                    >
                      <path stroke="#fff" d="M10.373 9h19.272" />
                      <path
                        stroke="#fff"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        d="M10.42.5 1.48 9l8.942 8.5"
                      />
                    </svg>
                  </NavLeft>
                  <NavRight className={s.buttonRight}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={30}
                      height={18}
                      fill="none"
                    >
                      <path stroke="#fff" d="M.373 9h19.272" />
                      <path
                        stroke="#fff"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        d="M19.645.5 28.587 9l-8.942 8.5"
                      />
                    </svg>
                  </NavRight>
                </div>
              </div>
            </div>
          </div>
        </CarouselContextProvider>
      </div>
    </div>
  )
}

export default HeroSlider
