import React, { useContext} from "react"
// import { GeneralContext } from "../../contexts/generalContext"
import Image from "../imagev2"
import CLink from "../cLink"
import tw, { css } from "twin.macro"
import htmlFilters from "../../helpers/htmlFilters";

import { GeneralContext } from "../../contexts/generalContext"



const CallOut = ({ content }) => {
  const { title, texto_con_formato1, texto_corto1, url, texto_corto2, blocks } = content?.data
  const generalContext = useContext(GeneralContext)
  const { ctaModalShow } = generalContext
  const image = blocks[0] ? blocks[0].image : null
  const image_mobile = blocks[0] ? blocks[0].image_mobile : null
  const finalUrl = texto_corto2 || url || ""
  return (
    <div css={tw`relative`}>
      {image && (<div
        css={imageBackground}
      >
        <Image
          objectFit={"cover"}
          imageTablet={image}
          imageMobile={image_mobile}
          widths={["880px", "980px", "1200px"]}
        />
      </div>)}
      <div css={tw`relative flex flex-col items-center justify-center px-4 py-8 md:py-16 relative`}>
        <span css={tw`text-obmed4 text-white font-bold uppercase`}>{title}</span>
        <p css={tw`mt-2 mb-6 text-obmed text-white`}>{htmlFilters(texto_con_formato1)}</p>
        {finalUrl && (<>{(finalUrl === "[showContactForm]" || finalUrl === "[showRdvForm]") ? (
          <span css={tw`block p-4 text-center text-white text-obmed2 md:text-obmed4 font-bold uppercase bg-marron1 hover:bg-marron2 cursor-pointer`}
            onClick={() => ctaModalShow(finalUrl === "[showContactForm]" ? "contacto" : "cita")}
          >
            {texto_corto1}
          </span>
        ) : (
          <CLink css={tw`block p-4 text-center text-white text-obmed2 md:text-obmed4 font-bold uppercase bg-marron1 hover:bg-marron2 cursor-pointer`}
            to={finalUrl}>
            {texto_corto1}
          </CLink>
        )}</>
        )}


      </div>
    </div >
  )
}

export default CallOut

const imageBackground = css`
position: absolute;
width: 100%;
top: 0;
right: 0;
left: 0;
bottom: 0;
`
// &::after {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background: linear-gradient(
//     to top,
//     rgba(0, 0, 0, 0) 0%,
//     rgba(0, 0, 0, 0.4) 100%
//   );
// }