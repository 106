import React from "react"
import tw, { css } from "twin.macro"
import htmlFilters from "../../helpers/htmlFilters";

const TituloYParrafo = ({ content }) => {
  const { title, texto_con_formato1 } = content.data
  return (
    <div
      css={tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden`}
    >
      <div css={tw`px-5 md:px-9 `}>
        <div css={tw`max-w-screen-xl my-0 mx-auto px-0 pb-4 md:pb-8`}>
          <div
            css={tw`md:grid md:grid-cols-2 md:gap-1 text-sm leading-7 md:text-base border-t border-gris4 text-gris1 dark:text-gris3`}
          >
            <div css={tw`pb-1 pt-4 md:pt-8 self-start`}>
              <h4
                css={tw`md:text-obhyperbig font-normal`}

              >{htmlFilters(title)}</h4>
            </div>
            <div
              css={tw`pb-1 pt-4 md:pt-8 self-start font-light leading-16em md:leading-15em`}

            >{htmlFilters(texto_con_formato1)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TituloYParrafo
