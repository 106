
import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import TituloEnlace from "./tituloEnlace"
import TiendaCard from "../mini/tiendaCard"
import tw, { css } from "twin.macro"
import { GeneralContext } from "../../contexts/generalContext"

const TiendasPorCiudad = ({ content, lang }) => {
  const generalContext = useContext(GeneralContext)
  const { t } = generalContext

  if (!content?.data?.blocks) return null

  const tiendas = content.data.blocks.map((t, idx) => (
    {
      title: t.title,
      image: t.image,
      image_mobile: t.image_mobile,
      ...JSON.parse(t.texto_sin_formato1),
    }
  ))


  return (

    <div css={[tw`grid grid-cols-1 md:grid-cols-3`, twGap3px]}>
      {tiendas.map(tienda => {
        return (
          <TiendaCard
            tienda={tienda}
            t={t}
            key={tienda.title}
          />
        )
      })}
    </div>
  )
}
const twGap3px = css`
  gap: 3px;
`

export default TiendasPorCiudad
