import React from 'react'
import tw,{ css } from 'twin.macro'
import Animacion from '../mini/animacion'
import htmlFilters from '../../helpers/htmlFilters'

const AnimacionConTexto = ({content}) => {
    return (
        
        <div css={[tw`relative md:grid md:grid-cols-12 bg-white`, cssCajaBlanca]}>
            <div css={[tw`relative md:col-span-7 md:col-start-6 md:order-2`, cssAnimacionNoCuadradaContenedor]}>
                <Animacion twStyles={cssAnimacionNoCuadrada} animationData={content.data.texto_sin_formato1} />
            </div>
            <div css={tw`relative md:col-span-5 md:col-start-1 md:order-1 px-5 md:px-10 py-5 md:py-10 `}>
                <div css={cssTexto}>{htmlFilters(content.data.texto_con_formato1)}</div>
            </div>
            
        </div>
                
    )
}


const cssAnimacionNoCuadrada = css`
    position: relative;
    top: -2rem;
    width: 80%;
    margin: 0 auto -2rem auto;
    @media (min-width: 768px) {
        position: absolute;
        top: -4rem;
        bottom: -2rem;
        width: 100%;
        margin: 0;
    }
`
const cssAnimacionNoCuadradaContenedor = css`
    ${tw`pointer-events-none`}
    position: relative;
    @media (min-width: 768px) {
        padding-top: 56%;
    }
`
const cssCajaBlanca = css`
    margin-top: 2rem;
    @media (min-width: 768px) {
        margin-top: 4rem;
        margin-bottom: 2rem;
    }
`

const cssTexto = css`
    *{
        ${tw`text-gris1`}
        font-size: 0.6875rem;
        line-height: 1.8em;
    }
    @media (min-width: 768px) {
        *{
            font-size: 0.8125rem;
            line-height: 1.7em;
        }
    }
`

export default AnimacionConTexto