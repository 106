import React, { useContext} from "react"
// import { GeneralContext } from "../../contexts/generalContext"
// import Image from "../imagev2"
// import CLink from "../cLink"
import tw, { css } from "twin.macro"
// import htmlFilters from "../../helpers/htmlFilters";
import { GeneralContext } from "../../contexts/generalContext"



const BotonPedirCita = ({ content }) => {
  const generalContext = useContext(GeneralContext)
  const { ctaModalShow, t } = generalContext
  return (
    <div css={tw`relative mt-12`}>
          <span css={tw`inline-block min-w-[180px] py-2 px-8 text-center text-white text-obmed2 md:text-obmed4 font-bold uppercase bg-marron1 hover:bg-marron2 cursor-pointer`}
            onClick={() => ctaModalShow("cita")}
          >
            {t.stores.pideCita}
          </span>
        
    </div>
  )
}

export default BotonPedirCita

const imageBackground = css`
position: absolute;
width: 100%;
top: 0;
right: 0;
left: 0;
bottom: 0;
`
// &::after {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background: linear-gradient(
//     to top,
//     rgba(0, 0, 0, 0) 0%,
//     rgba(0, 0, 0, 0.4) 100%
//   );
// }