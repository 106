
import * as React from "react"
import PropTypes from "prop-types"
import { useSpring, animated as a } from '@react-spring/web'
import tw from 'twin.macro';

const Plus = ({ color, hover }) => {

  const containerStyle = useSpring({ //todo
    config: {
      // mass: 100,
      tension: 400,
      friction: 50,
    },
    // transform1: hover ? "rotate(0deg)" : "rotate(-180deg)",
    // transform2: hover ? "rotate(0deg)" : "rotate(180deg)",
  })
  return (
    <a.div
      css={tw`inline-block`}
      styles={containerStyle}
    >
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <a.line x1="0" y1="6.5" x2="13" y2="6.5" stroke={color}  />
            <a.line x1="6.5" y1="0" x2="6.5" y2="13" stroke={color}  />
        </svg>
    </a.div>
  )
}

Plus.propTypes = {
  color: PropTypes.string,
  hover: PropTypes.bool,
}

Plus.defaultProps = {
  color: '#ffffff',
  hover: false,
}

export default Plus

