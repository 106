import React from 'react'
import Img from 'gatsby-image'

const Image = (imageData) => {
    
    // const imageData = {
    //     aspectRatio: 1.2,
    //     alt: 'Alt text',
    //     base64: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4QDeRXhpZgAASUkqAAgAAAAGABIBAwABAAAAAQAAABoBBQABAAAAVgAAABsBBQABAAAAXgAAACgBAwABAAAAAgAAABMCAwABAAAAAQAAAGmHBAABAAAAZgAAAAAAAAA4YwAA6AMAADhjAADoAwAABwAAkAcABAAAADAyMTABkQcABAAAAAECAwCGkgcAFgAAAMAAAAAAoAcABAAAADAxMDABoAMAAQAAAP//AAACoAQAAQAAAAgAAAADoAQAAQAAAAYAAAAAAAAAQVNDSUkAAABQaWNzdW0gSUQ6IDEzNP/bAEMACAYGBwYFCAcHBwkJCAoMFA0MCwsMGRITDxQdGh8eHRocHCAkLicgIiwjHBwoNyksMDE0NDQfJzk9ODI8LjM0Mv/bAEMBCQkJDAsMGA0NGDIhHCEyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMv/CABEIAAYACAMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAABP/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAGYQf8A/8QAFxAAAwEAAAAAAAAAAAAAAAAAAgMEEv/aAAgBAQABBQLYzN//xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oACAEDAQE/AX//xAAVEQEBAAAAAAAAAAAAAAAAAAAxAP/aAAgBAgEBPwFb/8QAGRAAAQUAAAAAAAAAAAAAAAAAAQACAxLB/9oACAEBAAY/ArRF41f/xAAYEAEBAAMAAAAAAAAAAAAAAAABEQAhMf/aAAgBAQABPyEFmHZeM//aAAwDAQACAAMAAAAQ+//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxBAcL//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QAGz/xAAYEAEBAAMAAAAAAAAAAAAAAAABEQAxgf/aAAgBAQABPxAXcFQCaEvc/9k=',
    //     src: 'http://picsum.photos/seed/1/800/600',
    //     srcSet: `http://picsum.photos/seed/1/200/160 220w, http://picsum.photos/seed/1/400/300 440w, http://picsum.photos/seed/1/200/800 880w, http://picsum.photos/seed/1/1000/400 980w, http://picsum.photos/seed/1/1200/800 1200w, http://picsum.photos/seed/1/2000/1600 2000w`,
    // }
    const { aspectRatio, alt, base64, src, srcSet, objectFit, sizes } = imageData
    let fAspectRatio = aspectRatio
    let fBase64 = base64
    let fSrc = src
    let fSrcSet = srcSet

    //Si falla la imagen móbil, la sustituye por la table y borra la anterior
    if(typeof fSrc === 'object' && !fBase64[0]){
        fAspectRatio = fAspectRatio[1]
        fBase64 = fBase64[1]
        fSrc = fSrc[1]
        fSrcSet = fSrcSet[1]
    }

    
    let fluid = {}
    if(typeof fSrc === 'object'){
        fluid = [{
            aspectRatio: fAspectRatio[0] || 1.333,
            base64: fBase64[0],
            sizes: sizes || "10px",
            src: fSrc[0],
            srcSet: fSrcSet[0],
        },
        {
            aspectRatio: fAspectRatio[1] || 1.333,
            base64: fBase64[1],
            sizes: sizes || "10px",
            src: fSrc[1],
            srcSet: fSrcSet[1],
            media: `(min-width: 768px)`,
        }]
        
    } else {
        fluid = {
            aspectRatio: fAspectRatio || 1.333,
            base64: fBase64,
            sizes: sizes || "10px",
            src: fSrc,
            srcSet: fSrcSet,
        }
    }
    return (
        <Img
            fluid={fluid}
            alt={alt || ''}
            // loading="eager"
            backgroundColor="#666666"
            objectFit={objectFit && "contain"}
            style={{
                width: "100%",
                height: "100%",
            }}
        />
    )
}

export default Image