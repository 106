import React from "react"
import tw, { css } from "twin.macro"
import htmlFilters from "../../helpers/htmlFilters";

const Parrafo = ({ content }) => {
  const tipo = content.data.title_tipo
  return (
    <div>
      {/* <div dangerouslySetInnerHTML={{ __html: content.data.title }}
            css={tw`text-gris1 text-obmed2 md:text-obhyperbig font-light uppercase`}
            /> */}
      <div
        css={
          tipo === "TIPO_1"
            ? css1
            : tipo === "TIPO_2"
            ? css2
            : tipo === "TIPO_3"
            ? css3
            : css4
        }
      >{htmlFilters(content.data.texto_con_formato1)}</div>
    </div>
  )
}

const css1 = css`
  & {
    ${tw`md:w-6/7 text-gris1  dark:text-gris3 text-obmed2 md:text-obhyperbig font-light uppercase md:leading-tight`}
  }
  strong {
    ${tw`font-normal`}
  }
`
const css2 = css`
  & {
    ${tw`md:w-2/3 pt-4 pb-8 text-gris1 dark:text-gris3 text-obmed2 md:text-base font-light`}
  }
  strong {
    ${tw`font-normal`}
  }
`
const css3 = css`
  & {
    ${tw`md:w-6/7 text-gris1  dark:text-gris3 text-obmed2 md:text-obhyperbig font-light`}
    ${tw`leading-16em md:leading-125em`}
  }
  strong {
    ${tw`font-normal`}
  }
`
const css4 = css`
  & {
    ${tw`py-4 text-obsmall text-gris1 dark:text-gris3`}
  }
  @media (min-width: 768px) {
    column-count: 2;
    column-gap: 1rem;
  }
  @media (min-width: 1024px) {
    column-count: 3;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`pb-4`}
    ${tw`text-obsmall md:text-sm`}
        ${tw`uppercase`}
        ${tw`font-bold`}
        line-height: 1.8em;
  }
  p {
    ${tw`pb-4`}
    line-height: 1.8em;
  }
  a {
    ${tw`underline`}
    ${tw`text-marron1`}
  }
  strong,
  b {
    ${tw`font-bold`}
  }
`

export default Parrafo
