import React, { createContext, useState, useCallback } from "react"
import { CarouselProps } from "./Carousel"

export const CarouselContext = createContext({
  activeSlide: 0,
  updateSlide: (slide: number) => {},
  updateSlidesQty: (qty: number) => {},
  nextSlide: () => {},
  prevSlide: () => {},
  onClickCallBack: () => {},
})

export interface CarouselContextProps {
  children: React.ReactNode
  onClickCallBack: () => void
}



const CarouselContextProvider = (props:CarouselContextProps) => {
  const { children, onClickCallBack } = props
  const [slideQty, setSlideQty] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(0)
  const updateSlide = useCallback(slide => {
    setCurrentSlide(slide)
  }, [])
  const updateSlidesQty = useCallback(qty => {
    setSlideQty(qty)
  }, [])

  const nextSlide = useCallback(() => {
    if (currentSlide < slideQty - 1) {
      setCurrentSlide(currentSlide + 1)
    } else {
      setCurrentSlide(0)
    }
  }, [currentSlide, slideQty])
  const prevSlide = useCallback(() => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1)
    } else {
      setCurrentSlide(slideQty - 1)
    }
  }, [currentSlide, slideQty])

  return (
    <CarouselContext.Provider
      value={{
        activeSlide: currentSlide,
        updateSlide,
        updateSlidesQty,
        nextSlide,
        prevSlide,
        onClickCallBack
      }}
    >
      {children}
    </CarouselContext.Provider>
  )
}
export default CarouselContextProvider
