import React, { useContext, useRef } from 'react'
import CLink from '../cLink'
import Image from '../image'
import Arrow from '../vector/arrow'
import tw from 'twin.macro';
import { useSpring, animated as a } from '@react-spring/web'
import useScroll from "../../helpers/useScroll"
import { useInView } from 'react-intersection-observer'


const HomeCocinasDestacadas = ({content}) => {
    const windowData = useScroll()
    const { ref, inView } = useInView({
        /* Optional options */
        // threshold: 0,
        triggerOnce: true,
    })
    return (<ul ref={ref} css={tw`md:grid md:grid-cols-3 md:gap-1`}>
            {content.data.blocks.map((block, i) => (
                <Cocina key={block.url+i} isVisible={inView} block={block} i={i} windowSize={windowData.windowSize} />
            ))
            }
        </ul>
    )
}

const Cocina = ({block, windowSize, isVisible, i}) => {
    const [ hover, setHover ] = React.useState(false)
    const initSpring = useSpring({
        transform: isVisible ? 'translate(0vh,0vh)' : 'translate(0vh,100vh)',
        opacity: isVisible ? 1 : 0,
        delay: 600 * i
    })
    const backgroundSpring = useSpring({
        x: windowSize.width > 767 && hover ? '-6rem' : '-40rem',
        y: windowSize.width > 767 && hover ? '16rem' : '10rem',
        rotate: windowSize.width > 767 && hover ? '45deg' : '70deg',
        }
    )
    const textSpring = useSpring({
        opacity: windowSize.width > 767 && hover ? 1 : 0,
        }
    )

    return (
        <a.li style={initSpring}>
                <CLink
                    to={block.url}
                    css={tw`block relative mt-2 overflow-hidden`}
                    onMouseEnter={() => {setHover(true)}}
                    onMouseLeave={() => {setHover(false)}}
                >
                    <Image
                        aspectRatio={block.image.aspectratio}
                        alt={block.image.description}
                        base64={block.image.base64}
                        src={block.image.url220}
                        srcSet={`${block.image.url220} 220w, ${block.image.url440} 440w`}
                    />
                    <span //mobile background
                        css={tw`block md:hidden absolute inset-0 top-0 px-5 py-3 bg-bggrisimage z-10`}
                    />
                    <a.span //tablet background
                        css={tw`hidden md:block absolute -inset-60 bg-bggrisimage2 z-10`}
                        style={backgroundSpring}
                    />
                    <a.div
                        css={tw`block absolute inset-0 top-0 md:top-auto md:bottom-0 px-5 py-3 z-20`}
                        style={textSpring}
                    >
                        <span
                            dangerouslySetInnerHTML={{__html: block.title}}
                            css={tw`text-sm md:text-base uppercase text-white font-light z-10 mr-4`}
                        />
                        <span
                            css={tw`block md:inline-block md:align-middle`}
                        >
                            <Arrow />
                        </span>
                    </a.div>
                    
                </CLink>
            </a.li>
    )
}

export default HomeCocinasDestacadas