import React from "react"
import tw from "twin.macro"
import CLink from "../cLink"
import Arrow from "../vector/arrow"
import { useInView } from "react-intersection-observer"
import { useSpring, animated as a } from "@react-spring/web"
import useScroll from "../../helpers/useScroll"

const TituloEnlace = ({ content }) => {
  const windowData = useScroll()
  const { isDarkMode } = windowData
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  const transitionSpring = useSpring({
    transform: !inView ? "translate(-40vw,0vw)" : "translate(0vw,0vw)",
    opacity: !inView ? 0 : 1,
  })

  const CustomTag = content.data.htmltag ? content.data.htmltag : 'div'
  console.log(content)

  return (
    <div
      ref={ref}
      css={[
        tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden`,
        content.data.checkbox1 && tw`bg-black dark:bg-gris6`,
      ]}
    >
      <div css={tw`px-5 md:px-9 `}>
        <a.div
          css={tw`max-w-screen-xl my-0 mx-auto px-0`}
          style={transitionSpring}
        >
          <CustomTag>
            <CLink
              to={content.data.url}
              css={[
                tw`flex items-center  relative py-5 text-obmed2 md:text-obmed uppercase text-marron1 dark:text-marron2 font-semibold md:font-bold align-middle`,
                content.data.checkbox1 && tw`text-white dark:text-white `,
              ]}
            >
              <div css={tw`absolute -left-50vw w-50vw`}>
                <div
                  css={[
                    tw`absolute right-2 md:right-4 w-full h-0.25 bg-marron1 dark:bg-marron2 mb-1`,
                    content.data.checkbox1 && tw`bg-white`,
                  ]}
                />
              </div>
              <span css={tw`block mr-2 md:mr-3`}>{content.data.title}</span>
              <span css={tw`block`} style={{ fontSize: "0px" }}>
                <Arrow
                  color={
                    content.data.checkbox1
                      ? "#ffffff"
                      : isDarkMode
                        ? "#D1C6B5"
                        : "#847360"
                  }
                />
              </span>
            </CLink>
          </CustomTag>
        </a.div>
      </div>
    </div>
  )
}

export default TituloEnlace
