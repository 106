import React from "react"
import Image from "../imagev2"
import tw from "twin.macro"

const Imagen = ({ content }) => {
  const { data } = content
  const block = data.blocks[0]
  return (
    <div css={tw`pb-1`}>
      <Image
        objectFit={"contain"}
        imageTablet={block.image}
        imageMobile={block.image_mobile}
        widths={["880px", "980px", "1200px"]}
      />
    </div>
  )
}

export default Imagen
