import React from "react"
const svg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={18}
    fill="none"
    viewBox="0 0 27 18"
    {...props}
  >
    <path
      fill="#9F927D"
      d="M25.345.144 13.167 11.71.98.144h24.365ZM.01 1.132c-.013.12-.013.24 0 .36v14.406h.385l7.588-7.203L.01 1.132ZM25.932 15.898h.435V1.492a1.677 1.677 0 0 0-.05-.36L18.344 8.67l7.588 7.228Z"
    />
    <path
      fill="#9F927D"
      d="m17.347 9.65 8.015 7.605H.966L8.989 9.65l3.701 3.51a.678.678 0 0 0 .947 0l3.71-3.51Z"
    />
  </svg>
)
export default svg
