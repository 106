import React, { useContext } from "react"
import tw from "twin.macro"
import DescargaPdf from "../mini/descargaPdf"
import useScroll from "../../helpers/useScroll"

const DescargaPdfPw = ({ content }) => {
  const { data } = content
  const { title, descarga, texto_sin_formato1: dataName, texto_con_formato1: dataType } = data
  const windowData = useScroll()
  const { isDarkMode } = windowData

  return (
    <div css={[tw`pt-3 pb-5 text-right`, data.isCentered && tw`text-center`]}>
      <DescargaPdf
        texto={title}
        enlace={descarga}
        color={isDarkMode ? "#D1C6B5" : "#847360"}
        bgColor={isDarkMode ? "#2C2B2B" : "#F0F0F0"}
        showInMobile={data.showInMobile}
        isCentered={data.isCentered}
        {  ...(dataName && {dataName: dataName} )}
        {  ...(dataType && {dataType: dataType} )}
      />
    </div>
  )
}

export default DescargaPdfPw
