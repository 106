import React, { useContext, useMemo } from "react"
import tw, { css } from "twin.macro"
import SvgCita from "../../images/tiendaCardCalendar_svg"
import SvgContacto from "../../images/tiendaCardContact_svg"
import { GeneralContext } from "../../contexts/generalContext"

const TiendaCard = ({ tienda, t, }) => {
  const generalContext = useContext(GeneralContext)
  const { ctaModalShow } = generalContext
  const formatedTel = useMemo(() => {
    const phone = tienda.tel
    let cleaned = phone.replace(/\D/g, '');
    // Si el número no comienza con '34', añadirlo
    if (!cleaned.startsWith('34')) {
      cleaned = '34' + cleaned;
    }
  
    // Asegurarse de que el número tenga la longitud correcta
    if (cleaned.length !== 11) {
      return ''
    }
  
    // Formatear el número
    return `+${cleaned.slice(0, 2)} ${cleaned.slice(2, 5)} ${cleaned.slice(5, 8)} ${cleaned.slice(8)}`;
  }, [tienda])
  return (
    <div css={[tw`px-6 py-7 bg-white text-gris1 flex flex-col justify-between`, cardContainerCss]}>
      <div css={tw`text-[14px] font-bold leading-[1.5em] uppercase`} style={{ fontFamily: 'Open Sans' }}>
        {tienda.title}
      </div>
      <div css={tw`text-[14px]  font-normal uppercase`}>{tienda.localidad}</div>
      <div css={tw`mt-3 flex-1 text-[13px] leading-[22px]`}>
        <p>{tienda.direccion}</p>
        <p>{`${tienda.cp} ${tienda.ciudad}`}</p>
        {tienda.tel && (
          <p>
            Tel:{' '}
            <a href={`tel:${tienda.tel}`} css={tw`font-bold text-gris1`}>{formatedTel}</a>
          </p>
        )}
      </div>
      <hr css={tw`my-6`} />
      <div css={[tw`flex justify-between text-[14px]`, buttonsCss]}>
        <div css={tw`flex items-center space-x-2 cursor-pointer`} onClick={() => ctaModalShow('cita', tienda.idcliente)}>
          <div css={cssIconSvg}>
          <SvgCita />
          </div>
          <span css={tw`text-marron1 font-semibold uppercase`}>{t?.stores?.pedirUnaCita}</span>
        </div>
        <div css={tw`flex items-center space-x-2 cursor-pointer`} onClick={() => ctaModalShow('contacto', tienda.idcliente)}>
          <div css={cssIconSvg}>
            <SvgContacto />
          </div>
          <span css={tw`text-marron1 font-semibold uppercase `}>{t?.stores?.contactar}</span>
        </div>
      </div>
    </div>



  )
}

const cssIconSvg = css`
  svg{
    width:24px;
    height:24px;
    & *{
      fill: #847360;
    }
  }
`

const cardContainerCss = css`
  container-type: inline-size;
`
const buttonsCss = css`
  flex-direction: column;
    gap: 8px;

  @container (min-width: 268px) {
    flex-direction: row;
    gap: 0;
  }
`

export default TiendaCard
