import React, { useRef, Suspense } from "react"
import tw, { css } from "twin.macro"
import ClientOnly from "../../helpers/ClientOnly"
import ImageLogo from "../../images/logoOB.inline.svg"
import htmlFilters from "../../helpers/htmlFilters";

const MapaContacto3D = React.lazy(() => import("../3d/mapaContacto3d"))

const MapaContactoPW = props => {
  const { content } = props
  const textoRef = useRef()

  return (
    <div css={twMapaContainer}>
      <ClientOnly>
        <div tw="absolute inset-0">
          <Suspense fallback={null}>
            <MapaContacto3D {...props} textoRef={textoRef} />
          </Suspense>
        </div>
      </ClientOnly>
      <TextBox
        textoRef={textoRef}
        text1={content.data.texto_con_formato1}
        text2={content.data.texto_con_formato2}
      />
    </div>
  )
}

const TextBox = ({ text1, text2, textoRef }) => {
  return (
    <div ref={textoRef} css={twTextBoxContainer}>
      <div css={twTextBox}>
        <ImageLogo />
        <div
          css={[
            tw`pt-6 text-obsmall2 md:text-obmed2 leading-6 text-gris1  dark:text-gris3`,
            cssTexto,
          ]}
        >{htmlFilters(text1)}</div>
        <div
          css={[
            tw`md:pb-4 text-obsmall2 md:text-obmed2 leading-6 text-gris1 dark:text-gris3`,
            cssTexto,
            cssAddressCols,
          ]}
        >{htmlFilters(text2)}</div>
      </div>
    </div>
  )
}

export default MapaContactoPW

const twMapaContainer = css`
  position: relative;
  min-height: 600px;
  margin: -50px -20px;
  z-index: -1;
  padding: 240px 20px 100px 20px;
  @media (min-width: 768px) {
    margin: -100px 0;
    min-height: 600px;
    padding: 140px 10% 130px 0;
  }
`

const twTextBoxContainer = css`
  position: relative;
  margin: 0 0 0 auto;
  ${tw`bg-transparent backdrop-filter backdrop-blur-sm`}
  ${tw`border`}
  ${tw`border-marron1`}
  @media (min-width: 768px) {
    width: 40%;
  }
`

const twTextBox = css`
  background-color: rgba(255, 255, 255, 0.6);
  @media (min-width: 768px) {
    background-color: rgba(255, 255, 255, 1);
  }
  ${tw`p-6`}
`
const cssTexto = css`
  h3,
  h4,
  h5,
  h6 {
    ${tw`pb-4`}
    ${tw`text-obsmall md:text-sm`}
        ${tw`uppercase`}
        ${tw`font-bold`}
        ${tw`dark:text-gris3`}
  }
  p {
    ${tw`pb-4`}
    ${tw`dark:text-gris3`}
  }
  a {
    ${tw`underline`}
    ${tw`text-marron1`}
  }
`
const cssAddressCols = css`
  ${tw`md:flex`}
  & > * {
    @media (min-width: 768px) {
      flex: 1;
    }
    &:not(:last-child) {
      @media (min-width: 768px) {
        margin-right: 8px;
      }
    }
  }
`
