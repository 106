import React from "react"
// import CLink from '../cLink'
import Image from "../imagev2"
// import Arrow from '../vector/arrow'
import tw, { css, styled } from "twin.macro"
import Animacion from "../mini/animacion"
import htmlFilters from "../../helpers/htmlFilters";

const Grid = ({ content }) => {
  const { data } = content
  const Texto = ({ block }) => (
    <div
      css={[tw`py-4 text-obsmall text-gris1  dark:text-gris3`, cssTexto]}
    >{htmlFilters(block.texto_con_formato1)}</div>
  )
  let gridCeldas = []
  data.blocks.forEach((block, i) => {
    const celda = {
      content: null,
    }
    switch (block.type) {
      case "imagen_y_texto":
        celda.content = (
          <div>
            {block.image && (
              <Image
                objectFit={"contain"}
                imageTablet={block.image}
                widths={["880px", "880px", "880px"]}
                // aspectRatio={block.image.aspectratio}
                // alt={block.image.description}
                // base64={block.image.base64}
                // src={block.image.url880}
                // srcSet={`${block.image.url220} 220w, ${block.image.url440} 440w, ${block.image.url880} 880w, ${block.image.url980} 980w, ${block.image.url1760} 1200w, ${block.image.url2000} 2000w`}
              />
            )}
            <Texto block={block} i={i} />
          </div>
        )
        break
      case "imagen":
        celda.content = (
          <div css={[block.checkbox1 && content100Percent]}>
            {block.image && (
              <Image
                objectFit={block.checkbox1 ? "cover" : "contain"}
                imageTablet={block.image}
                imageMobile={block.image_mobile}
                widths={["880px", "880px", "880px"]}
              />
            )}
          </div>
        )
        break
      case "animacion":
        celda.content = (
          <div css={tw`relative bg-white`}>
            <Animacion
              animationData={block.texto_sin_formato1}
              animationSpeed={block.texto_corto1}
            />
          </div>
        )
        break

      // case 'vacio':
      //     celda = (
      //         <div />
      //     )
      //     break;
      default:
        break
    }
    gridCeldas.push(celda)
  })
  const stylesPW = JSON.parse(data.styles)
  return (
    <SuperGrid
      styleColumns={stylesPW["grid-template-columns"]}
      styleRows={stylesPW["grid-template-rows"]}
      styleAreas={stylesPW["grid-template-areas"].replace(/&quot;/g, '"')}
      styleGap={stylesPW["gap"]}
    >
      {gridCeldas.map((celda, i) => {
        return (
          <CellGrid styleGridArea={`b${i + 1}`} key={i}>
            {celda.content}
          </CellGrid>
        )
      })}
    </SuperGrid>
  )
}

const SuperGrid = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: ${props => props.styleColumns};
    grid-template-rows: ${props => props.styleRows};
    grid-template-areas: ${props => props.styleAreas};
    gap: ${props => props.styleGap};
  }
  & > * {
    @media (max-width: 767px) {
      margin-bottom: ${props => props.styleGap};
    }
  }
`
const CellGrid = styled.div`
  @media (min-width: 768px) {
    grid-area: ${props => props.styleGridArea};
  }
`

const cssTexto = css`
  h3,
  h4,
  h5,
  h6 {
    ${tw`pb-4`}
    ${tw`text-obsmall md:text-sm`}
        ${tw`uppercase`}
        ${tw`font-bold`}
        line-height: 1.8em;
  }
  p {
    ${tw`pb-4`}
    line-height: 1.8em;
  }
  a {
    ${tw`underline`}
    ${tw`text-marron1`}
  }
  strong,
  b {
    ${tw`font-bold`}
  }
`
const content100Percent = css`
  position: relative;
  width: 100%;
  height: 100%;
`

// const Cocina = ({block, windowSize}) => {
//     const [ hover, setHover ] = React.useState(false)

//     const backgroundSpring = useSpring({
//         x: windowSize.width > 767 && hover ? '-6rem' : '-40rem',
//         y: windowSize.width > 767 && hover ? '16rem' : '10rem',
//         rotate: windowSize.width > 767 && hover ? '45deg' : '70deg',
//         }
//     )
//     const textSpring = useSpring({

//         opacity: windowSize.width > 767 && hover ? 1 : 0,
//         }
//     )

//     return (
//         <li>
//                 <CLink
//                     to={block.url}
//                     css={tw`block relative mt-2 overflow-hidden`}
//                     onMouseEnter={() => {setHover(true)}}
//                     onMouseLeave={() => {setHover(false)}}
//                 >
//                     <Image
//                         aspectRatio={block.image.aspectratio}
//                         alt={block.image.description}
//                         base64={block.image.base64}
//                         src={block.image.url}
//                         srcSet={`${block.image.url} 220w, ${block.image.url} 440w, ${block.image.url} 880w, ${block.image.url} 980w, ${block.image.url} 1200w, ${block.image.url} 2000w`}
//                     />
//                     <span //mobile background
//                         css={tw`block md:hidden absolute inset-0 top-0 px-5 py-3 bg-bggrisimage z-10`}
//                     />
//                     <a.span //tablet background
//                         css={tw`hidden md:block absolute -inset-60 bg-bggrisimage2 z-10`}
//                         style={backgroundSpring}
//                     />
//                     <a.div
//                         css={tw`block absolute inset-0 top-0 md:top-auto md:bottom-0 px-5 py-3 z-20`}
//                         style={textSpring}
//                     >
//                         <span
//                             dangerouslySetInnerHTML={{__html: block.title}}
//                             css={tw`text-sm md:text-base uppercase text-white font-light z-10 mr-4`}
//                         />
//                         <span
//                             css={tw`block md:inline-block md:align-middle`}
//                         >
//                             <Arrow />
//                         </span>
//                     </a.div>

//                 </CLink>
//             </li>
//     )
// }

export default Grid
