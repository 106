import React from "react"
import isClient from "../../helpers/isClient"

const HTMLCode = ({ content }) => {
  const { data } = content
  const htmlCode = decodeHTMLEntities(data.texto_sin_formato1)

  return (
    <div data-name="htmlcode-block"
    dangerouslySetInnerHTML={{ __html: htmlCode }} />
  )
}

function decodeHTMLEntities(str) {
  let strConverted = str;
  if (isClient) {
    let txt = document.createElement("textarea");
    txt.innerHTML = str;
    strConverted = txt.value
  }
  return strConverted;
}

export default HTMLCode
