import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import TituloEnlace from "./tituloEnlace"
import { GeneralContext } from "../../contexts/generalContext"
import BlogCard from "../mini/blogCard"
import tw from "twin.macro"

const BlogLastPosts = ({ content, lang }) => {
  const generalContext = useContext(GeneralContext)
  const { t } = generalContext
  const data = useStaticQuery(graphql`
    query blogModuleQuery {
      blogPageInCurrentLang: allSitePage(filter: {context: {pwid: {eq: 1}}}) {
    nodes {
      context {
        lang
        ruta
      }
    }
  }
  processwire {
    config {
      es {
        blog_categorias {
          ruta
          title
        }
      }
      en {
        blog_categorias {
          ruta
          title
        }
      }
      fr {
        blog_categorias {
          ruta
          title
        }
      }
      pt {
        blog_categorias {
          ruta
          title
        }
      }
    }
  }
  lastBlogEntries_es: allSitePage(
    filter: {context: {template: {eq: "page_blog_entry"}, lang: {eq: "es"}}}
    limit: 3
  ) {
    nodes {
      context {
        title
        ruta
        lang
        texto_blog_introduccion
        blog_categorias
        image {
          width
          
          url880
          url440
          url220
          url2000
          url1760
          url220webp
          url440webp
          url880webp
          
          url1760webp
          url2000webp
          url
          height
          description
          base64
          aspectratio
        }
        image_mobile {
          width
          
          url880
          url440
          url220
          url2000
          url1760
          url220webp
          url440webp
          url880webp
          
          url1760webp
          url2000webp
          url
          height
          description
          base64
          aspectratio
        }
      }
    }
  }
  lastBlogEntries_fr: allSitePage(
    filter: {context: {template: {eq: "page_blog_entry"}, lang: {eq: "fr"}}}
    limit: 3
  ) {
    nodes {
      context {
        title
        ruta
        lang
        texto_blog_introduccion
        blog_categorias
        image {
          width
          
          url880
          url440
          url220
          url2000
          url1760
          url220webp
          url440webp
          url880webp
          
          url1760webp
          url2000webp
          url
          height
          description
          base64
          aspectratio
        }
        image_mobile {
          width
          
          url880
          url440
          url220
          url2000
          url1760
          url220webp
          url440webp
          url880webp
          
          url1760webp
          url2000webp
          url
          height
          description
          base64
          aspectratio
        }
      }
    }
  }
  lastBlogEntries_pt: allSitePage(
    filter: {context: {template: {eq: "page_blog_entry"}, lang: {eq: "pt"}}}
    limit: 3
  ) {
    nodes {
      context {
        title
        ruta
        lang
        texto_blog_introduccion
        blog_categorias
        image {
          width
          
          url880
          url440
          url220
          url2000
          url1760
          url220webp
          url440webp
          url880webp
          
          url1760webp
          url2000webp
          url
          height
          description
          base64
          aspectratio
        }
        image_mobile {
          width
          
          url880
          url440
          url220
          url2000
          url1760
          url220webp
          url440webp
          url880webp
          
          url1760webp
          url2000webp
          url
          height
          description
          base64
          aspectratio
        }
      }
    }
  }
  lastBlogEntries_en: allSitePage(
    filter: {context: {template: {eq: "page_blog_entry"}, lang: {eq: "en"}}}
    limit: 3
  ) {
    nodes {
      context {
        title
        ruta
        lang
        texto_blog_introduccion
        blog_categorias
        image {
          width
          
          url880
          url440
          url220
          url2000
          url1760
          url220webp
          url440webp
          url880webp
          
          url1760webp
          url2000webp
          url
          height
          description
          base64
          aspectratio
        }
        image_mobile {
          width
          
          url880
          url440
          url220
          url2000
          url1760
          url220webp
          url440webp
          url880webp
          
          url1760webp
          url2000webp
          url
          height
          description
          base64
          aspectratio
        }
      }
    }
  }
}
  `)
  // const entradas = data[`lastBlogEntries_${lang}`].nodes
  const entradas = data.lastBlogEntries_es.nodes.filter(n => n.context.lang === lang).slice(0,3)

  const categorias = data.processwire.config[lang].blog_categorias
  const categoriasPorRuta = {}
  categorias.map(categoria => {
    categoriasPorRuta[categoria.ruta] = categoria
  })

  return (
    <>

      {/* Entradas */}
      <div  css={tw`md:grid md:grid-cols-3 md:gap-x-6`}>
        {entradas.map(entrada => {
          return (
            <div key={entrada.context.ruta}>
              <BlogCard
                entrada={entrada.context}
                t={t}
                categoriasPorRuta={categoriasPorRuta}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default BlogLastPosts
