import React, {Suspense} from 'react'
import Mapa from '../3d/mapa'

const MapaPw = ({lang}) => {

    return (
        <Mapa lang={lang} />
    )
}


export default MapaPw