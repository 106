import React, { useRef, Suspense, useContext } from "react"

import tw from "twin.macro"
// import { cTransitionProps } from "../cLink"
// import { useTriggerTransition } from "../../helpers/DummyGatsbyPluginTransitionLink"

import { Canvas, useFrame, useThree } from "@react-three/fiber"
import { Html, useGLTF, PerspectiveCamera, Line } from "@react-three/drei"

import { useSpring, animated, config } from "@react-spring/three"
import * as THREE from "three"

import filter from "lodash.filter"
import { GeneralContext } from "../../contexts/generalContext"
import { translateCustom } from "../../langs/langHelpers"
import { stringToSlug } from "../../helpers/stringToSlug"
// import Simple1DNoise from '../../helpers/simple1DNoise'

// MIRAR ESTO -> https://github.com/pmndrs/react-three-fiber/discussions/769
const Mapa3D = props => {
  const { zonaActual, zonaAnterior, listaCiudades, lang } = props
  ////const triggerTransition = useTriggerTransition(cTransitionProps) @todo
  const triggerTransition = ({to}) => {
    if(typeof window !== 'undefined'){
      window.location.href = to
    }
  }
  const generalContext = useContext(GeneralContext)
  const { t } = generalContext
  const goToCiudad = ciudadSlug => {
    const ciudad = filter(listaCiudades, { slug: ciudadSlug })[0]
    triggerTransition({ to: ciudad.context.ruta })
  }
  return (
    <Canvas linear={true} flat={true} dpr={[1, 2]} transparent>
      <Camara zonaActual={zonaActual} zonaAnterior={zonaAnterior} />
      <Suspense fallback={null}>
        <VectorMapa {...props} langCode={lang} goToCiudad={goToCiudad} />
      </Suspense>
    </Canvas>
  )
}

const VectorMapa = props => {
  const {
    canvasContainerRef,
    textosProvinciasLiRef,
    zonaActual,
    cambiaZona,
    listaZonas,
    listaCiudades,
    scrollToCiudad,
    provinciaHover,
    setProvinciaHover,
    goToCiudad,
    langCode,
  } = props

  const { nodes } = useGLTF("/3d/objects/mapa.glb")
  //onsole.log(nodes)
  const mapMaterial = new THREE.MeshBasicMaterial({
    color: 0xdfd7cb,
    transparent: true,
    opacity: 0,
  })

  return (
    <group dispose={null}>
      <group name="mapamundi_marco" userData={{ name: "mapamundi_marco" }} />
      {
        //Ciudades zona general
        listaCiudades.map(ciudad => {
          const nameText = `${ciudad.slug}-p`
          const nameMap = `${ciudad.slug}-m`
          const slug = ciudad.slug
          if (!nodes[nameText] || ciudad.zona !== "general") {
            return null
          }

          return (
            <mesh
              key={nameText}
              name={nameText}
              geometry={nodes[nameText].geometry}
              material={mapMaterial}
              userData={{ name: nameText }}
            >
              <LineaGeneral
                slug={slug}
                nameText={nameText}
                nameMap={nameMap}
                zonaGeneral={zonaActual === "general"}
                textNode={nodes[nameText]}
                mapNode={nodes[nameMap]}
                zonaActual={zonaActual}
              />
              <Ciudad
                slug={slug}
                objectName={nameText}
                ciudad={ciudad}
                nodes={nodes}
                zonaActual={zonaActual}
                textosProvinciasLiRef={textosProvinciasLiRef}
                canvasContainerRef={canvasContainerRef}
                goToCiudad={goToCiudad}
                langCode={langCode}
              />
            </mesh>
          )
        })
      }
      {
        //PROVINCIAS AREAS
        listaCiudades.map((ciudad, i) => {
          const nameArea = `${ciudad.slug}-a`
          const slug = ciudad.slug
          if (!nodes[nameArea]) {
            return null
          }

          return (
            <group key={i}>
              <ProvinciaArea
                nameArea={nameArea}
                slug={slug}
                zona={ciudad.zona}
                goToCiudad={goToCiudad}
                zonaActual={zonaActual}
                areaNode={nodes[nameArea]}
                scrollToCiudad={scrollToCiudad}
                textosProvinciasLiRef={textosProvinciasLiRef}
                ciudad={ciudad}
                hover={provinciaHover === slug}
                setProvinciaHover={setProvinciaHover}
                i={i}
              />
            </group>
          )
        })
      }
      {
        //PROVINCIAS LINEAS
        listaCiudades.map((ciudad, i) => {
          const nameArea = `${ciudad.slug}-a`
          const slug = ciudad.slug
          if (!nodes[nameArea]) {
            return null
          }
          //onsole.log(nameArea)
          return (
            <group key={i}>
              <ProvinciaLineaNivel2
                slug={slug}
                zona={ciudad.zona}
                nameArea={nameArea}
                canvasContainerRef={canvasContainerRef}
                zonaActual={zonaActual}
                nodes={nodes}
                textosProvinciasLiRef={textosProvinciasLiRef}
                hover={provinciaHover === slug}
              />
            </group>
          )
        })
      }
      {/* TEXTO, LINEA de ZONAS */}
      {listaZonas.map(zona => {
        const nameText = `${stringToSlug(zona)}-p`
        const nameMap = `${stringToSlug(zona)}-m`
        const slug = stringToSlug(zona)
        if (!nodes[nameText]) {
          return null
        }
        return (
          <mesh
            key={nameText}
            name={nameText}
            castShadow
            receiveShadow
            geometry={nodes[nameText].geometry}
            userData={{ name: nameText }}
          >
            {/* PUNTO */}
            <meshBasicMaterial transparent color={0xffffff} opacity={0} />
            <LineaGeneral
              slug={slug}
              nameText={nameText}
              nameMap={nameMap}
              zonaGeneral={zonaActual === "general"}
              // nodes={nodes}
              textNode={nodes[nameText]}
              mapNode={nodes[nameMap]}
              zonaActual={zonaActual}
            />
            {/* TEXTO PAIS */}
            <Zona
              slug={slug}
              objectName={nameText}
              title={translateCustom("countries", zona, langCode)}
              nodes={nodes}
              zonaActual={zonaActual}
              cambiaZona={cambiaZona}
              textosProvinciasLiRef={textosProvinciasLiRef}
              canvasContainerRef={canvasContainerRef}
              scrollToCiudad={scrollToCiudad}
            />
          </mesh>
        )
      })}
      {/* FONDO MAPAS DE PAISES */}
      {listaZonas.map(zona => {
        const slug = stringToSlug(zona)
        const nameFondo = `${slug}-f`
        if (!nodes[nameFondo]) {
          return null
        }
        //onsole.log(zona, slug)
        if (zonaActual !== slug) return null
        //onsole.log(zonaActual)
        //onsole.log(nodes[nameFondo])

        return (
          <mesh
            key={nameFondo}
            geometry={nodes[nameFondo].geometry}
            position={[0, -0.001, 0]}
          >
            <animated.meshBasicMaterial color={0xdcd2c6} />
          </mesh>
        )
      })}
      {/* MAPAMUNDI */}

      <MeshTransparent
        name="mapamundi-mapa"
        geometry={nodes["mapamundi-mapa"].geometry}
        zona="general"
        color={0xdfd7cb}
        userData={{ name: "mapamundi-mapa" }}
        zonaActual={zonaActual}
      />
      {/* FONDO */}
      <mesh
        // onClick={() => cambiaZona('general')}
        position={[4.61979, -3, -5]}
        rotation={[-Math.PI * 0.5, 0, 0]}
      >
        <planeBufferGeometry args={[20, 20]} />
        <meshBasicMaterial color={0xffffff} transparent opacity={0} />
      </mesh>
    </group>
  )
}

const Camara = props => {
  const { zonaActual, zonaAnterior } = props
  const lerpSpeed = 0.05

  const camaraRef = useRef()
  const meshPosRef = useRef()

  const meshLookRef = useRef()
  const camaraPositions = {
    general: {
      p: [4.41979, 4.95831, -2.31922],
      look: [4.61979, 0, -2.31922],
      // r: [0,0,0]
    },
    espana: {
      p: [4.35, 0.27, -2.74],
      look: [4.35, 0, -2.84],
      // r: [0.694,0,0]
    },
    francia: {
      p: [4.26408 + 0.20183, 0.27, -2.74 - 0.2],
      look: [4.26 + 0.20183, 0, -2.84007 - 0.2],
      // r: [0.694,0,0]
    },
    portugal: {
      p: [4.22, 0.2, -2.84],
      look: [4.22, 0, -2.84],
      // r: [0.694,0,0]
    },
  }
  const springCamara = useSpring({
    to: {
      position:
        zonaActual === "espana"
          ? camaraPositions.espana.p
          : zonaActual === "francia"
          ? camaraPositions.francia.p
          : zonaActual === "portugal"
          ? camaraPositions.portugal.p
          : camaraPositions.general.p,
      look:
        zonaActual === "espana"
          ? camaraPositions.espana.look
          : zonaActual === "francia"
          ? camaraPositions.francia.look
          : zonaActual === "portugal"
          ? camaraPositions.portugal.look
          : camaraPositions.general.look,
    },
    config: {
      mass: 20,
      tension: 170,
      friction: 126,
    },
  })
  // const noiseGeneratorX = new Simple1DNoise()
  // const noiseGeneratorZ = new Simple1DNoise()

  // const mounted = useRef(false);
  // useEffect(() => {
  //     mounted.current = true;
  //     onsole.log("Camara montada")
  //     return () => {
  //         mounted.current = false;
  //     onsole.log("Camara DESmontada")

  //     };
  // }, []);

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime()
    if (camaraRef.current) {
      // const camNoiPos = {
      //   x: (noiseGeneratorX.getVal(a)-0.5)*(zonaActual==='general' ? 0.02 : 0.005),
      //   z: (noiseGeneratorZ.getVal(a+635)-0.5)*(zonaActual==='general' ? 0.02 : 0.005),
      // }

      if (meshPosRef.current) {
        camaraRef.current.position.set(
          meshPosRef.current.position.x,
          meshPosRef.current.position.y,
          meshPosRef.current.position.z
        )
      }
      if (meshLookRef.current) {
        camaraRef.current.lookAt(
          meshLookRef.current.position.x,
          meshLookRef.current.position.y,
          meshLookRef.current.position.z
        )
      }

      camaraRef.current.rotation.z = 0
      camaraRef.current.updateProjectionMatrix()
    }
  })
  return (
    <>
      <animated.mesh ref={meshPosRef} position={springCamara.position} />
      <animated.mesh ref={meshLookRef} position={springCamara.look} />
      <PerspectiveCamera ref={camaraRef} far={200} fov={52} makeDefault />
    </>
  )
}

const Ciudad = ({
  slug,
  objectName,
  listaCiudades,
  nodes,
  zonaActual,
  textosProvinciasLiRef,
  canvasContainerRef,
  ciudad,
  goToCiudad,
  langCode,
}) => {
  const pos = getCenterPoint(nodes[objectName])
  const lado = pos.x > 5 ? "der" : "izq"

  // onsole.log(slug)
  // onsole.log(listaCiudades)
  return (
    <Html
      as="div" // Wrapping element (default: 'div')
      calculatePosition={(el, camera, size) => {
        const objectPos = getCenterPoint(el.parent)
        objectPos.project(camera)
        const widthHalf = size.width / 2
        const heightHalf = size.height / 2
        return [
          objectPos.x * widthHalf + widthHalf,
          -(objectPos.y * heightHalf) + heightHalf,
        ] //Toni fix ¿?
      }}
    >
      <a
        href={ciudad.context.ruta}
        onClick={() => goToCiudad(slug)}
        css={[
          tw`block bg-fondo1 transform -translate-y-1/2 py-2 px-2 border whitespace-nowrap border-marron1 text-marron1 uppercase text-obsmall font-bold`,
          lado === "der" && tw`-translate-x-100x100`,
        ]}
      >
        {translateCustom(
          "countries",
          ciudad.context.donde_comprar_pais,
          langCode
        )}
      </a>
    </Html>
  )
}

const Zona = ({
  slug,
  objectName,
  title,
  nodes,
  zonaActual,
  cambiaZona,
  scrollToCiudad,
  textosProvinciasLiRef,
  canvasContainerRef,
}) => {
  const pos = getCenterPoint(nodes[objectName])
  const lado = pos.x > 5 ? "der" : "izq"
  // onsole.log(slug)
  // onsole.log(listaCiudades)
  return (
    <Html
      as="div" // Wrapping element (default: 'div')
      calculatePosition={(el, camera, size) => {
        const objectPos = getCenterPoint(el.parent)
        objectPos.project(camera)
        const widthHalf = size.width / 2
        const heightHalf = size.height / 2
        return [
          objectPos.x * widthHalf + widthHalf,
          -(objectPos.y * heightHalf) + heightHalf,
        ] //Toni fix ¿?
      }}
    >
      <div
        css={[
          tw`block bg-marron1 text-fondo1 transform -translate-y-1/2 py-2 px-2 border whitespace-nowrap border-marron1 uppercase text-obsmall font-bold cursor-pointer`,
          lado === "der" && tw`-translate-x-100x100`,
        ]}
        onClick={() => cambiaZona(slug)}
      >
        {title}
      </div>
    </Html>
  )
}

const LineaGeneral = ({
  objectName,
  nameText,
  nameMap,
  zonaGeneral,
  textNode,
  mapNode,
  zonaActual,
}) => {
  const ref = useRef()
  const posText = getCenterPoint(textNode)
  const lado = posText.x > 5 ? "der" : "izq"
  const posMid =
    lado === "izq"
      ? [posText.x + 1.0, posText.y, posText.z]
      : [posText.x - 1.0, posText.y, posText.z]
  const posMap = getCenterPoint(mapNode)

  if (!zonaGeneral) return null
  return (
    <Line
      ref={ref}
      points={[
        [posText.x, posText.y, posText.z],
        posMid,
        [posMap.x, posMap.y, posMap.z],
      ]} // Array of points
      color="#847360" // Default
      lineWidth={0.5} // In pixels (default)
      // dashed={false}                  // Default
      // vertexColors={[[0, 0, 0], ...]} // Optional array of RGB values for each point
      // {...lineProps}                  // All THREE.Line2 props are valid
      // {...materialProps}              // All THREE.LineMaterial props are valid
    />
  )
}

//Material con color y spring
const MeshTransparent = props => {
  const ref = useRef()
  const lerpSpeed = 0.05

  const { name, geometry, zona, color, userData, zonaActual } = props
  useFrame(() => {
    ref.current.opacity = THREE.MathUtils.lerp(
      ref.current.opacity,
      zonaActual === zona ? 1 : 0,
      lerpSpeed
    )
    //onsole.log(ref.current.opacity)
  })
  return (
    <mesh geometry={geometry}>
      <meshBasicMaterial ref={ref} transparent={true} color={0xd1c5b3} />
    </mesh>
  )
}
const ProvinciaArea = ({
  nameArea,
  slug,
  i,
  zona,
  zonaActual,
  areaNode,
  scrollToCiudad,
  setProvinciaHover,
  hover,
  goToCiudad,
}) => {
  const spring = useSpring({
    color: hover ? "#B3A495" : "#d1c5b3",
  })
  if (zonaActual !== zona) return null
  return (
    <mesh
      key={nameArea}
      name={nameArea}
      geometry={areaNode.geometry}
      userData={{ name: nameArea }}
      onPointerEnter={e => {
        scrollToCiudad(slug)
      }}
      onPointerLeave={e => {
        setProvinciaHover(null)
      }}
      onClick={() => {
        goToCiudad(slug)
      }}
    >
      <animated.meshBasicMaterial
        // ref={ref}
        // transparent={true}
        // opacity={0}

        color={spring.color}
      />
    </mesh>
  )
}
const ProvinciaLineaNivel2 = ({
  slug,
  nameArea,
  zona,
  nodes,
  canvasContainerRef,
  zonaActual,
  textosProvinciasLiRef,
  hover,
}) => {
  const lineRef = useRef()
  const materialRef = useRef()
  const centerArea = getCenterPointofGeometry(nodes[nameArea].geometry)
  const { camera } = useThree()
  const node = nodes[nameArea]

  useFrame(({ clock }) => {
    if (node && zonaActual === zona) {
      const liDomElement = textosProvinciasLiRef.current[slug]
      if (!liDomElement || !canvasContainerRef.current) return null
      const canvasDomRect = canvasContainerRef.current.getBoundingClientRect()
      const textDomRect = liDomElement.getBoundingClientRect()
      const y =
        2 *
          ((canvasDomRect.height - (textDomRect.top + 17 - canvasDomRect.top)) /
            canvasDomRect.height) -
        1 //
      const point1 = new THREE.Vector3(0.96, y, 0).unproject(camera)
      if (lineRef.current) {
        const vertices = new Float32Array([
          point1.x,
          point1.y,
          point1.z,
          (point1.x + centerArea.x) * 0.5,
          point1.y,
          point1.z,
          centerArea.x,
          centerArea.y,
          centerArea.z,
        ])
        lineRef.current.copyArray(vertices)
        lineRef.current.needsUpdate = true

        //if(nameArea==='algarve-a') onsole.log(vertices)
        //if(nameArea==='cordoba-a') onsole.log(vertices)
        // if(nameArea==='cordoba-a') onsole.log(y)
        materialRef.current.opacity = Math.cos(
          Math.max(Math.min(y, 1.0), -1.0) * Math.PI * 0.5
        )
        materialRef.current.lineWidth = 10
      }
    }
  })

  if (zonaActual !== zona) return null
  return (
    <line depthWrite={false} depthTest={true}>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          ref={lineRef}
          attachObject={["attributes", "position"]}
          array={new Float32Array([0, 0, 0, 0, 0, 0, 0, 0, 0])}
          count={9 / 3}
          itemSize={3}
          // onUpdate={update}
        />
      </bufferGeometry>
      <lineBasicMaterial
        depthWrite={false}
        depthTest={true}
        ref={materialRef}
        color={hover ? 0x847360 : 0xb3a495}
        lineWidth={2}
        opacity={0.1}
        transparent={true}
      />
    </line>
  )
}

export default Mapa3D

function getCenterPoint(mesh) {
  if (!mesh) {
    return [0, 0, 0]
  }
  var geometry = mesh.geometry
  geometry.computeBoundingBox()
  var center = new THREE.Vector3()
  geometry.boundingBox.getCenter(center)
  mesh.localToWorld(center)
  return center
}
function getCenterPointofGeometry(geometry) {
  if (!geometry) {
    return new THREE.Vector3()
  }
  const mesh = new THREE.Mesh(geometry, null)
  geometry.computeBoundingBox()
  const center = new THREE.Vector3()
  geometry.boundingBox.getCenter(center)
  mesh.localToWorld(center)
  return center
}
