import React, { useRef } from 'react'
import CLink from '../cLink'
import ArrowStatic from '../vector/arrowStatic'
import tw, { css } from 'twin.macro'

const EnlaceInternoEnColumna = ({ url, title }) => {
    return(
        <div>
            <CLink
                css={tw`inline-block py-2 border-t border-b border-marron1 text-base font-semibold text-marron1 uppercase`}
            to={url}>{title}
            <span css={tw`pl-3`}><ArrowStatic color={"#847360"} /></span></CLink>
        </div>
    )
}

export default EnlaceInternoEnColumna