import React, { Suspense } from 'react'
import tw, { styled } from 'twin.macro'
import ClientOnly from "../../helpers/ClientOnly"
import TituloEnlace from './tituloEnlace'
import htmlFilters from "../../helpers/htmlFilters";
import RepeatIconoYTitulo from "./subcomps/repeatIconoYTitulo";

const VideoPlayer = React.lazy(() => import('../mini/videoPlayer'));



const VideoConTexto = ({ content }) => {

    const ParteVideo = ({ estaALaDerecha }) => {
        return (<div css={[tw`md:flex-1`, estaALaDerecha ? tw`md:ml-4` : tw`md:mr-4`]}>
            <ClientOnly>
                <Suspense fallback={(<div css={tw`relative w-full h-0`} style={{ paddingBottom: '56.25%' }} />)}>
                    <VideoPlayer content={content} />
                </Suspense>
            </ClientOnly>
        </div>)
    }

    const ParteTexto = ({ estaALaIzquierda }) => {
        const iconosYTitulos = content.data?.texto_sin_formato1 ? JSON.parse(content.data.texto_sin_formato1) : []
        return (
        <div css={[tw`md:flex-1 md:block px-5 md:px-0 py-2 md:py-0`, content.data.checkbox2 ? tw`hidden` : tw`block`, estaALaIzquierda ? tw`md:mr-4` : tw`md:ml-4`]}>
            <div>
            <StyledParrafo>
                {htmlFilters(content.data.texto_con_formato1)}
            </StyledParrafo>
            {iconosYTitulos.length > 0 && (<RepeatIconoYTitulo items={iconosYTitulos} />)}
            </div>
        </div>
        )
    }
    const tieneTitulo = content.data.url && content.data.title
    return (
        <>
            {tieneTitulo && <TituloEnlace
                content={{
                    data: {
                        url: content.data.url,
                        title: content.data.title,
                        checkbox1: true,
                    }
                }}
            />
            }
            <div css={[tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden bg-black dark:bg-gris6`, !tieneTitulo && tw`md:pt-6`]}>
                <div css={tw`md:px-9 md:pb-6`}>
                    <div css={tw`max-w-screen-xl my-0 mx-auto px-0 pb-4 md:pb-0 md:flex`}>
                        {
                            content.data.checkbox1 ? //Video a la derecha
                                <>
                                    <ParteTexto estaALaIzquierda />
                                    <ParteVideo estaALaDerecha />
                                </>
                                :
                                <>
                                    <ParteVideo />
                                    <ParteTexto />
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const StyledParrafo = styled.div`
    ${tw`pt-3 md:pt-0`}
    p{
        ${tw`text-white text-obsmall2 lg:text-base font-normal leading-5`}
        margin-top: 1rem;
        &:first-of-type{
            margin-top: 0;
        }
    }
    a{
        font-weight: 400;
        text-decoration: underline;
    }
`



export default VideoConTexto