// extracted by mini-css-extract-plugin
export var backgroundContainer = "heroSlider-module--backgroundContainer--zL-ek";
export var backgroundImage = "heroSlider-module--backgroundImage--b7dM5";
export var bullets = "heroSlider-module--bullets--vkchd";
export var buttonLeft = "heroSlider-module--buttonLeft--aKce8";
export var buttonRight = "heroSlider-module--buttonRight--v93Nh";
export var container = "heroSlider-module--container--wCGFc";
export var isActive = "heroSlider-module--isActive--dAXXC";
export var navButtons = "heroSlider-module--navButtons--jV7PB";
export var navButtonsContainer = "heroSlider-module--navButtonsContainer--vaNhg";
export var slideText = "heroSlider-module--slideText--po-TO";
export var slideTextContainer = "heroSlider-module--slideTextContainer--S7P+H";
export var sliderTextAnimations = "heroSlider-module--sliderTextAnimations--QVGvt";
export var titleLink = "heroSlider-module--titleLink--DLxBv";