import React, { Suspense } from 'react'
import ClientOnly from "../../helpers/ClientOnly"

// import PlanificaCocina3D from '../3d/planificaCocina3d'
const PlanificaCocina3D = React.lazy(() => import('../3d/planificaCocina3d'));

const PlanificaCocina = (props) => {

    return (
        <ClientOnly>
            <Suspense  fallback={<div />}>
                <PlanificaCocina3D {...props}/>
            </Suspense>
        </ClientOnly>
        
    )
}


export default PlanificaCocina