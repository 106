import React, { useRef, useContext, useEffect, useCallback } from "react"
import * as s from "./carousel.module.css"
// import { useSprings, animated as a } from "@react-spring/web"
// import { useDrag } from "react-use-gesture"
import CarouselContextProvider, { CarouselContext } from "./Carousel.context"

type Slide = {
  MainSlide: React.ReactNode
  BackgroundSlide: React.ReactNode
}

export interface CarouselProps {
  slides: Slide[]
  containerClassName?: string
  autoplayTime?: number
}

export const Carousel = (props: CarouselProps) => {
  const sliderElRef = useRef(null)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const scrollTimerRed = useRef<any | null>(null)

  const {
    slides,
    containerClassName,
    autoplayTime = 0,
  } = props
  const carouselContext = useContext(CarouselContext)
  const {
    activeSlide,
    updateSlidesQty,
    updateSlide,
    // onClickCallBack,
  } = carouselContext
  const updateSlidesPosition = useCallback(
    position => {
      slides.forEach((_, idx) => {
        // @todo cambiar animación a transición de opacidad
        const backgroundSlide = document.getElementById(
          `tcq-slide-background-${idx}`
        )
        if (backgroundSlide) {
          backgroundSlide.style.transform = `translate3d(${
            -1 * position * window.innerWidth
          }px,0,0)`
        }
      })
    },
    [slides]
  )

  const onSlideMove = useCallback(
    event => {
      //actualizamos el temporizador para que sepamos que el scroll se mueve
      if (scrollTimerRed.current !== null) {
        clearTimeout(scrollTimerRed.current) // Limpia el temporizador si ya existe uno
      }
      scrollTimerRed.current = setTimeout(function () {
        // Esta función se ejecuta cuando el usuario ha dejado de hacer scroll
        onSlideStop(event)
      }, 150) // Espera 150 ms despué
      // obtenemos la posición del scroll
      const scrollPosition = event.target.scrollLeft
      const slidePosition = scrollPosition / event.target.clientWidth
      updateSlidesPosition(slidePosition)
    },
    [slides]
  )

  const onSlideStop = useCallback(
    event => {
      const scrollPosition = event.target.scrollLeft
      const slidePosition = scrollPosition / event.target.clientWidth
      const nextSlide = Math.round(slidePosition)
      if (nextSlide !== activeSlide) {
        updateSlide(nextSlide)
      }
    },
    [slides]
  )

  const changeToSlide = activeSlide => {
    if (sliderElRef?.current) {
      sliderElRef.current.scrollTo({
        left: activeSlide * window.innerWidth,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    if (slides?.length > 0) {
      updateSlidesQty(slides.length)
    }
  }, [slides])

  useEffect(() => {
    changeToSlide(activeSlide)
  }, [activeSlide])

  useEffect(() => {
    if (sliderElRef?.current) {
      sliderElRef.current.addEventListener("scroll", onSlideMove)
    }
    return () => {
      if (sliderElRef?.current) {
        sliderElRef.current.removeEventListener("scroll", onSlideMove)
      }
    }
  }, [sliderElRef])

  const autoPlayNext = useCallback(({ _activeSlide, _slideQty }) => {
    if (_activeSlide < _slideQty - 1) {
      updateSlide(_activeSlide + 1)
    } else {
      updateSlide(0)
    }
  }, [])

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      autoPlayNext({ _activeSlide: activeSlide, _slideQty: slides.length })
    }, autoplayTime)

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [activeSlide])

  return (
    <div ref={sliderElRef} className={`${s.slider} ${containerClassName}`}>
      {slides.map((slide, idx) => (
        <div className={s.slide} key={idx}>
          {slide.MainSlide}
        </div>
      ))}
    </div>
  )
}

export const CarouselBackground = (props: CarouselProps) => {
  const { slides, containerClassName } = props
  return (
    <div className={`${s.sliderBackground} ${containerClassName}`}>
      {slides.map((slide, idx) => (
        <div
          id={`tcq-slide-background-${idx}`}
          className={s.slideBackground}
          key={idx}
          style={{
            transform: `translate3d(${idx * 100}vw,0,0)`,
          }}
        >
          {slides[idx].BackgroundSlide}
        </div>
      ))}
    </div>
  )
}

export const NavRight = ({ children, className }) => {
  const carouselContext = useContext(CarouselContext)
  const { nextSlide, onClickCallBack } = carouselContext
  const onClick = useCallback(() => {
    onClickCallBack()
    nextSlide()
  },[nextSlide])
  return (
    <span onClick={onClick} className={className}>
      {children}
    </span>
  )
}
export const NavLeft = ({ children, className }) => {
  const carouselContext = useContext(CarouselContext)
  const { prevSlide, onClickCallBack } = carouselContext
  const onClick = useCallback(() => {
    onClickCallBack()
    prevSlide()
  },[prevSlide])
  return (
    <span onClick={onClick} className={className}>
      {children}
    </span>
  )
}

export const NavGoTo = ({
  children,
  className,
  activeClassName,
  slideNumber,
}) => {
  const carouselContext = useContext(CarouselContext)
  const { updateSlide, activeSlide, onClickCallBack } = carouselContext
  const onClick = useCallback(() => {
    onClickCallBack()
    updateSlide(slideNumber)
  },[slideNumber])
  return (
    <span
      onClick={onClick}
      className={`${className} ${
        slideNumber === activeSlide ? activeClassName : ""
      }`}
    >
      {children}
    </span>
  )
}

export { CarouselContext, CarouselContextProvider }
