import React, {useContext} from 'react'
import CLink from '../cLink'
import Image from '../image'
import tw from 'twin.macro';
import { useSpring, animated as a } from '@react-spring/web'
import useScroll from "../../helpers/useScroll"
import { useInView } from 'react-intersection-observer'

const ListadoCocinasSecundarias = ({content}) => {
    const windowData = useScroll()
    const { windowSize } = windowData
    return (
        <ul css={tw`grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-1 pb-1 text-center`}>
            {content.data.blocks.map((block, i) => (
                <Cocina key={i} number={i} block={block} windowSize={windowSize} />
            ))}
        </ul>
    )
}

const Cocina = ({block, windowSize, number}) => {
    const [ hover, setHover ] = React.useState(false)
    const { ref, inView } = useInView({
        /* Optional options */
        // threshold: 0,
        triggerOnce: true,
    })
    const springConfig = {
        tension: 20
    }
    const transitionSpring = useSpring({
        opacity: !inView ? 0 : 1,
        delay: number * 50,
        config: springConfig
    })

    const backgroundSpring = useSpring({
        opacity: windowSize.width < 768 || hover ? 1 : 0,
        scale: windowSize.width < 768 || hover ? 1.0 : 1.1,
    })

    return (
        <li ref={ref}>
            <a.div style={transitionSpring}>
            <CLink
                css={tw`block relative overflow-hidden`}
                to={block.url}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
                
            >
                    
                <div css={tw``}>
                    <Image
                        objectFit={"cover"}
                        aspectRatio={1}
                        alt={block.image.description}
                        base64={block.image.base64}
                        src={block.image.url220}
                        srcSet={`${block.image.url220} 220w, ${block.image.url440} 440w`}
                    />
                </div>
                <a.div
                    css={tw`flex absolute inset-0 justify-center items-end md:items-center bg-bggrisimage3 md:bg-bggrisimage2`}
                    style={backgroundSpring}
                ><div
                        dangerouslySetInnerHTML={{__html: block.title}}
                        css={tw`text-white text-obsmall md:text-obmed leading-4 pb-3 md:pb-0`}
                        // style={textSpring}
                    /></a.div>
            </CLink>
            </a.div>
        </li>
    )
}


export default ListadoCocinasSecundarias